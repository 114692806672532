import partyContract from "../utils/partyContract";
import tokenContract from "../utils/tokenConection";
import web3 from "../web3";
import { getUserAddress } from "./web3Actions";
import farmContract from "../utils/farmConnection";
import erc1155contract from "../utils/ERC1155OrareConnection";
import maticContract from "../utils/maticConnection";
import orareGameContract from "../utils/OrareGameConnection";
import marketContract from "../utils/marketplaceConnection";
import constants from "../utils/constants";

//get pool details
export const getPoolDetails = async (currentCategory) => {
  let result = await farmContract(currentCategory)
    .methods.getDetails()
    .call((err, res) => {
      return res;
    });

  return result;
};

//Update emission rate
export const updateEmissionRate = async (currentCategory, emissionRate) => {
  const userAddress = await getUserAddress();
  let result = await farmContract(currentCategory)
    .methods.updateRewardPerSec(emissionRate)
    .send({ from: userAddress, gas: 2100000, gasPrice: 8000000000 });
  return result;
};

//Update marketplace fee
export const updateMarketplaceFee = async (feePercent) => {
  const userAddress = await getUserAddress();
  let result = await marketContract.methods
    .updateFeePercent(feePercent)
    .send({ from: userAddress, gas: 2100000, gasPrice: 8000000000 });

  return result;
};

//get current marketplace fee
export const getCurrentMarketplaceFee = async () => {
  let result = await marketContract.methods
    .feePercent()
    .call((err, response) => {
      return response;
    });
  return result;
};
//Update marketplace fee
export const updateFeeManager = async (managerAddress) => {
  const userAddress = await getUserAddress();
  let result = await marketContract.methods
    .updateFeeManager(managerAddress)
    .send({ from: userAddress, gas: 2100000, gasPrice: 8000000000 });

  return result;
};

//get current marketplace fee
export const getCurrentFeeManager = async () => {
  let result = await marketContract.methods
    .feeManager()
    .call((err, response) => {
      return response;
    });
  return result;
};

//READ user matic Balance
export const getUserMaticBalance = async (userAddress) => {
  let result = await maticContract.methods
    .balanceOf(userAddress)
    .call((err, response) => {
      return response;
    });

  return parseFloat(web3.utils.fromWei(result.toString(), "ether")).toFixed(2);
};

//READ user Orare Balance
//RETURNS number
export const getOrareBalanceByAddress = async (userAddress) => {
  let result = await tokenContract.methods
    .balanceOf(userAddress)
    .call((err, response) => {
      return response;
    });

  return parseInt(web3.utils.fromWei(result.toString(), "ether"));
};

export const getUniqueNftIdsInPool = async (currentCategory) => {
  let result = await farmContract(currentCategory)
    .methods.getAllRewardNftIds()
    .call((err, res) => {
      return res;
    });
  return result;
};
export const updateNftIds = async (currentCategory, nftsIds) => {
  const userAddress = await getUserAddress();
  console.log(nftsIds);
  let result = await farmContract(currentCategory)
    .methods.addNftIds(nftsIds)
    .send({ from: userAddress });
  console.log(result);
  return result;
};

export const updateIngredientsQuantity = async (
  currentCategory,
  id,
  value,
  data
) => {
  const userAddress = await getUserAddress();
  let result = await erc1155contract.methods
    .mint(currentCategory, id, value, data)
    .send({ from: userAddress, gas: 2100000, gasPrice: 8000000000 });
  return result;
};

// get vault contract ids and balances
export const getVaultIdsAndBalances = async () => {
  let vault_contract = constants.vault_contract;
  let result = await erc1155contract.methods
    .getAllTokensOwned(vault_contract)
    .call((err, res) => {
      return res;
    });

  console.log(result);
  return result;
};

export const createNewDish = async (tokenURI, recipe) => {
  const userAddress = await getUserAddress();
  const ownerAddress = constants.vault_contract;
  let result = await orareGameContract.methods
    .createDish(false, ownerAddress, 0, tokenURI, `0x`, recipe)
    .send({ from: userAddress, gas: 2100000, gasPrice: 8000000000 });
  return result;
};

//READ owner of token contract
//RETURNS string
export const getTokenContractOwner = async () => {
  let result = await tokenContract.methods.owner().call((err, response) => {
    return response;
  });
  return result;
};

//READ owner of party contract
//RETURNS string
export const getPartyContractOwner = async () => {
  let result = await partyContract.methods.owner().call((err, response) => {
    return response;
  });
  return result;
};

//READ owner of token contract
//RETURNS string
export const getPartyEndTime = async () => {
  let result = await partyContract.methods
    .lockTimestamp()
    .call((err, response) => {
      return response;
    });
  return new Date(result);
};

import { makeStyles } from "@mui/styles";
import { Person, VerifiedUser } from "@mui/icons-material";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import {
  getDBUserOrareBalances,
  getFTWarsWinsAPI,
  getMostActiveUsersAPI,
} from "../../actions/serverActions";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "#0C0D11",
    marginTop: 20,
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    width: "100%",
    minHeight: 250,
    border: "1px solid #1b1d24",
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: 14,
    "&:hover": {
      boxShadow: "0px 24px 33px -9px #0000005C",
    },

    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
    },
  },
}));

const AnalyticsFTWarsUsers = () => {
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const [ftwarsUsers, setFtwarsUsers] = useState([]);

  // Get balance of users
  useEffect(() => {
    async function asyncFn() {
      let res = await getFTWarsWinsAPI();
      setFtwarsUsers(res);
    }
    asyncFn();
  }, []);

  return (
    <div className="mt-5">
      <Typography fontWeight={600} fontSize={18} color={"#f9f9f9"}>
        Top users by FTWars Wins
      </Typography>

      <Box mt={3}>
        {ftwarsUsers &&
          ftwarsUsers.slice(0, 10).map((singleUser, i) => (
            <Box
              key={i}
              style={{
                border: "1px solid #0C0D12",
                backgroundColor: "#111214",
                borderRadius: 14,
                padding: 10,
                paddingLeft: 20,
                paddingRight: 20,
                width: "auto",
              }}
              display={"flex"}
              justifyContent={"space-between"}
            >
              <Box
                width="45%"
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}
              >
                <IconButton
                  color="success"
                  style={{ backgroundColor: "#36393E", height: 30, width: 30 }}
                >
                  <Person style={{ fontSize: 16 }} />
                </IconButton>
                <Box ml={2}>
                  <Typography
                    fontWeight={400}
                    fontSize={11}
                    color={"#f9f9f9"}
                    textAlign={"left"}
                  >
                    {singleUser.userAddress}
                  </Typography>
                </Box>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                width="25%"
              >
                <Box ml={2}>
                  <Typography fontWeight={300} fontSize={11} color={"#e5e5e5"}>
                    Total Wins
                  </Typography>
                  <Typography fontWeight={600} fontSize={11} color={"#fff"}>
                    {singleUser.totalWins}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
      </Box>
    </div>
  );
};

export default AnalyticsFTWarsUsers;

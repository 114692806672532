import Web3 from "web3";
import constants from "./constants";

let gameConstant;
if (constants.net === 0) {
  gameConstant = {
    contractAddress: constants.game_contract,
    abi: [
      {
        inputs: [],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address[]",
            name: "accounts",
            type: "address[]",
          },
        ],
        name: "AdminAdded",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "previousAdmin",
            type: "address",
          },
          {
            indexed: false,
            internalType: "address",
            name: "newAdmin",
            type: "address",
          },
        ],
        name: "AdminChanged",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address[]",
            name: "accounts",
            type: "address[]",
          },
        ],
        name: "AdminRemoved",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "beacon",
            type: "address",
          },
        ],
        name: "BeaconUpgraded",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "dishId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "ingredients",
            type: "uint256[]",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "quantity",
            type: "uint256[]",
          },
        ],
        name: "DishCooked",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "uint256",
            name: "dishId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "ingredients",
            type: "uint256[]",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "quantity",
            type: "uint256[]",
          },
        ],
        name: "DishCreated",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "uint256",
            name: "dishId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "bool",
            name: "status",
            type: "bool",
          },
        ],
        name: "DishLockUpdated",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "uint256",
            name: "dishId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "ingredients",
            type: "uint256[]",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "quantity",
            type: "uint256[]",
          },
        ],
        name: "DishRecipeUpdated",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "ERC1155TokensRecovered",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "ERC20TokensRecovered",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "uint256[]",
            name: "dishId",
            type: "uint256[]",
          },
        ],
        name: "MultipleDishRecipeUpdated",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "previousOwner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "newOwner",
            type: "address",
          },
        ],
        name: "OwnershipTransferred",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "account",
            type: "address",
          },
        ],
        name: "Paused",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "account",
            type: "address",
          },
        ],
        name: "Unpaused",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "implementation",
            type: "address",
          },
        ],
        name: "Upgraded",
        type: "event",
      },
      {
        inputs: [
          {
            internalType: "address[]",
            name: "accounts_",
            type: "address[]",
          },
        ],
        name: "addAdmins",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "dishId_",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "uint256[]",
                name: "ingredients",
                type: "uint256[]",
              },
              {
                internalType: "uint256[]",
                name: "quantity",
                type: "uint256[]",
              },
            ],
            internalType: "struct Recipe",
            name: "recipe_",
            type: "tuple",
          },
        ],
        name: "addOrUpdateDishRecipe",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256[]",
            name: "dishIds_",
            type: "uint256[]",
          },
          {
            components: [
              {
                internalType: "uint256[]",
                name: "ingredients",
                type: "uint256[]",
              },
              {
                internalType: "uint256[]",
                name: "quantity",
                type: "uint256[]",
              },
            ],
            internalType: "struct Recipe[]",
            name: "recipies_",
            type: "tuple[]",
          },
        ],
        name: "addOrUpdateMultipleDishRecipe",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
        ],
        name: "admins",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "dishId_",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "owner_",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "data_",
            type: "bytes",
          },
        ],
        name: "cookDish",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "bool",
            name: "locked_",
            type: "bool",
          },
          {
            internalType: "address",
            name: "to_",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "initialSupply_",
            type: "uint256",
          },
          {
            internalType: "string",
            name: "uri_",
            type: "string",
          },
          {
            internalType: "bytes",
            name: "data_",
            type: "bytes",
          },
          {
            components: [
              {
                internalType: "uint256[]",
                name: "ingredients",
                type: "uint256[]",
              },
              {
                internalType: "uint256[]",
                name: "quantity",
                type: "uint256[]",
              },
            ],
            internalType: "struct Recipe",
            name: "recipe_",
            type: "tuple",
          },
        ],
        name: "createDish",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        name: "dish",
        outputs: [
          {
            internalType: "bool",
            name: "exist",
            type: "bool",
          },
          {
            internalType: "bool",
            name: "isLocked",
            type: "bool",
          },
          {
            components: [
              {
                internalType: "uint256[]",
                name: "ingredients",
                type: "uint256[]",
              },
              {
                internalType: "uint256[]",
                name: "quantity",
                type: "uint256[]",
              },
            ],
            internalType: "struct Recipe",
            name: "recipe",
            type: "tuple",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "getAllDishIds",
        outputs: [
          {
            internalType: "uint256[]",
            name: "",
            type: "uint256[]",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "dishId_",
            type: "uint256",
          },
        ],
        name: "getDishRecipe",
        outputs: [
          {
            internalType: "uint256[]",
            name: "ingredients",
            type: "uint256[]",
          },
          {
            internalType: "uint256[]",
            name: "quantity",
            type: "uint256[]",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "owner_",
            type: "address",
          },
          {
            internalType: "address",
            name: "oRareNft_",
            type: "address",
          },
        ],
        name: "initialize",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "oRareNft",
        outputs: [
          {
            internalType: "contract IORareNft",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "owner",
        outputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "pauseContract",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "paused",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "to_",
            type: "address",
          },
          {
            internalType: "address",
            name: "token_",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount_",
            type: "uint256",
          },
        ],
        name: "recoverERC20",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address[]",
            name: "accounts_",
            type: "address[]",
          },
        ],
        name: "removeAdmins",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "newOwner_",
            type: "address",
          },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "unpauseContract",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "dishId_",
            type: "uint256",
          },
          {
            internalType: "bool",
            name: "status_",
            type: "bool",
          },
        ],
        name: "updateDishLock",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "newImplementation",
            type: "address",
          },
        ],
        name: "upgradeTo",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "newImplementation",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "data",
            type: "bytes",
          },
        ],
        name: "upgradeToAndCall",
        outputs: [],
        stateMutability: "payable",
        type: "function",
      },
    ],
  };
} else {
  gameConstant = {
    contractAddress: constants.game_contract,
    abi: [
      {
        inputs: [],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address[]",
            name: "accounts",
            type: "address[]",
          },
        ],
        name: "AdminAdded",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "previousAdmin",
            type: "address",
          },
          {
            indexed: false,
            internalType: "address",
            name: "newAdmin",
            type: "address",
          },
        ],
        name: "AdminChanged",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address[]",
            name: "accounts",
            type: "address[]",
          },
        ],
        name: "AdminRemoved",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "beacon",
            type: "address",
          },
        ],
        name: "BeaconUpgraded",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "dishId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "ingredients",
            type: "uint256[]",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "quantity",
            type: "uint256[]",
          },
        ],
        name: "DishCooked",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "uint256",
            name: "dishId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "ingredients",
            type: "uint256[]",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "quantity",
            type: "uint256[]",
          },
        ],
        name: "DishCreated",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "uint256",
            name: "dishId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "bool",
            name: "status",
            type: "bool",
          },
        ],
        name: "DishLockUpdated",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "uint256",
            name: "dishId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "ingredients",
            type: "uint256[]",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "quantity",
            type: "uint256[]",
          },
        ],
        name: "DishRecipeUpdated",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "ERC1155TokensRecovered",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "ERC20TokensRecovered",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "uint256[]",
            name: "dishId",
            type: "uint256[]",
          },
        ],
        name: "MultipleDishRecipeUpdated",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "previousOwner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "newOwner",
            type: "address",
          },
        ],
        name: "OwnershipTransferred",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "account",
            type: "address",
          },
        ],
        name: "Paused",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "account",
            type: "address",
          },
        ],
        name: "Unpaused",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "implementation",
            type: "address",
          },
        ],
        name: "Upgraded",
        type: "event",
      },
      {
        inputs: [
          {
            internalType: "address[]",
            name: "accounts_",
            type: "address[]",
          },
        ],
        name: "addAdmins",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "dishId_",
            type: "uint256",
          },
          {
            components: [
              {
                internalType: "uint256[]",
                name: "ingredients",
                type: "uint256[]",
              },
              {
                internalType: "uint256[]",
                name: "quantity",
                type: "uint256[]",
              },
            ],
            internalType: "struct Recipe",
            name: "recipe_",
            type: "tuple",
          },
        ],
        name: "addOrUpdateDishRecipe",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256[]",
            name: "dishIds_",
            type: "uint256[]",
          },
          {
            components: [
              {
                internalType: "uint256[]",
                name: "ingredients",
                type: "uint256[]",
              },
              {
                internalType: "uint256[]",
                name: "quantity",
                type: "uint256[]",
              },
            ],
            internalType: "struct Recipe[]",
            name: "recipies_",
            type: "tuple[]",
          },
        ],
        name: "addOrUpdateMultipleDishRecipe",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
        ],
        name: "admins",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "dishId_",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "owner_",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "data_",
            type: "bytes",
          },
        ],
        name: "cookDish",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "bool",
            name: "locked_",
            type: "bool",
          },
          {
            internalType: "address",
            name: "to_",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "initialSupply_",
            type: "uint256",
          },
          {
            internalType: "string",
            name: "uri_",
            type: "string",
          },
          {
            internalType: "bytes",
            name: "data_",
            type: "bytes",
          },
          {
            components: [
              {
                internalType: "uint256[]",
                name: "ingredients",
                type: "uint256[]",
              },
              {
                internalType: "uint256[]",
                name: "quantity",
                type: "uint256[]",
              },
            ],
            internalType: "struct Recipe",
            name: "recipe_",
            type: "tuple",
          },
        ],
        name: "createDish",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        name: "dish",
        outputs: [
          {
            internalType: "bool",
            name: "exist",
            type: "bool",
          },
          {
            internalType: "bool",
            name: "isLocked",
            type: "bool",
          },
          {
            components: [
              {
                internalType: "uint256[]",
                name: "ingredients",
                type: "uint256[]",
              },
              {
                internalType: "uint256[]",
                name: "quantity",
                type: "uint256[]",
              },
            ],
            internalType: "struct Recipe",
            name: "recipe",
            type: "tuple",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "getAllDishIds",
        outputs: [
          {
            internalType: "uint256[]",
            name: "",
            type: "uint256[]",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "dishId_",
            type: "uint256",
          },
        ],
        name: "getDishRecipe",
        outputs: [
          {
            internalType: "uint256[]",
            name: "ingredients",
            type: "uint256[]",
          },
          {
            internalType: "uint256[]",
            name: "quantity",
            type: "uint256[]",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "owner_",
            type: "address",
          },
          {
            internalType: "address",
            name: "oRareNft_",
            type: "address",
          },
        ],
        name: "initialize",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "oRareNft",
        outputs: [
          {
            internalType: "contract IORareNft",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "owner",
        outputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "pauseContract",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "paused",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "to_",
            type: "address",
          },
          {
            internalType: "address",
            name: "token_",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount_",
            type: "uint256",
          },
        ],
        name: "recoverERC20",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address[]",
            name: "accounts_",
            type: "address[]",
          },
        ],
        name: "removeAdmins",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "newOwner_",
            type: "address",
          },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "unpauseContract",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "dishId_",
            type: "uint256",
          },
          {
            internalType: "bool",
            name: "status_",
            type: "bool",
          },
        ],
        name: "updateDishLock",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "newImplementation",
            type: "address",
          },
        ],
        name: "upgradeTo",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "newImplementation",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "data",
            type: "bytes",
          },
        ],
        name: "upgradeToAndCall",
        outputs: [],
        stateMutability: "payable",
        type: "function",
      },
    ],
  };
}

var web3;

if (typeof window.ethereum !== "undefined") {
  // web3 = new Web3(window.web3.currentProvider);
  // web3 = new Web3(window.ethereum);
  web3 = new Web3(window.ethereum);
} else {
  var provider =
    "https://polygon-mainnet.g.alchemy.com/v2/38R9Vnxi-6UPne8ACF4k4radrS8-6UJ1";
  var web3Provider = new Web3.providers.HttpProvider(provider);
  web3 = new Web3(web3Provider);
}

const orareGameContract = new web3.eth.Contract(
  gameConstant.abi,
  gameConstant.contractAddress
);

export default orareGameContract;

import { Close } from "@mui/icons-material";
import { Box, Button, Modal, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { getUserAddress } from "../../../actions/web3Actions";

const DeleteBrandPopup = ({ open, setOpen, minterAddress }) => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const [user, setUser] = useState(null);

  useEffect(() => {
    (async () => {
      const address = await getUserAddress();
      setUser(address);
    })();
  }, []);

  const onRemoveBrand = async () => {
    const data = JSON.stringify({
      address: minterAddress,
      // fromAddress: user,
      fromAddress: "0xb2b0a3311742c977895a7f89f64a68c8b78c334a",
    });
    var config = {
      method: "post",
      url: "https://superapp-backend.onerare.io/removeMinter",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await axios(config);
      console.log(response.data.result);
      setOpen(false);
    } catch (error) {
      console.log(error);
      setOpen(false);
    }
  };
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <Box
          style={{
            background: "rgba(0, 0, 0, 0.75)",
            border: "2px solid #D82B2B",
            boxShadow: "0px 0px 200px 1065px rgba(0, 0, 0, 0.75)",
            backdropFilter: "blur(8px)",
            height: "max-content",
            width: "100%",
            maxWidth: 450,
            padding: "35px 25px",
            borderRadius: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "25px",
            position: "relative",
            margin: "10px",
          }}
        >
          <Close
            style={{
              position: "absolute",
              right: 15,
              top: 15,
              fontSize: md?28:36,
              cursor: "pointer",
            }}
            onClick={() => setOpen(false)}
          />
          <Typography
            className="heading"
            style={{ fontSize:  md?28:36, marginBottom: 10 }}
          >
            Confirm Delete
          </Typography>
          <svg
            width="112"
            height="112"
            viewBox="0 0 112 112"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="31.2308"
              y="35.8976"
              width="49.5385"
              height="58.8718"
              rx="5.38462"
              stroke="#D82B2B"
              strokeWidth="6.46154"
              strokeLinejoin="round"
            />
            <line
              x1="48.8189"
              y1="58.1538"
              x2="48.8189"
              y2="81.8462"
              stroke="#D82B2B"
              strokeWidth="4.30769"
              strokeLinecap="round"
            />
            <line
              x1="85.4343"
              y1="26.5639"
              x2="26.5625"
              y2="26.5639"
              stroke="#D82B2B"
              strokeWidth="6.46154"
              strokeLinecap="round"
            />
            <line
              x1="62.8189"
              y1="58.1538"
              x2="62.8189"
              y2="81.8462"
              stroke="#D82B2B"
              strokeWidth="4.30769"
              strokeLinecap="round"
            />
            <path
              d="M65.3317 23.3333C65.3317 22.1077 65.0903 20.894 64.6212 19.7616C64.1522 18.6292 63.4647 17.6003 62.598 16.7337C61.7314 15.867 60.7025 15.1795 59.5701 14.7105C58.4377 14.2414 57.224 14 55.9984 14C54.7727 14 53.559 14.2414 52.4267 14.7105C51.2943 15.1795 50.2654 15.867 49.3987 16.7337C48.532 17.6004 47.8445 18.6292 47.3755 19.7616C46.9065 20.894 46.665 22.1077 46.665 23.3333"
              stroke="#D82B2B"
              strokeWidth="4.30769"
            />
          </svg>
          <Typography
            className="heading"
            style={{ fontSize: md?16:18, margin: "20px 0", textAlign: "center" }}
          >
            You are about to delete this brand.
          </Typography>
          <Button
            sx={{
              background: `#D82B2B`,
              color: "#fff",
              width: "fit-content",
              height: 46,
              textTransform: "none",
              borderRadius: 20,
              fontSize: md ? 14 : 18,
              padding: md ? "5px 10px" : "5px 20px",
              cursor: "pointer",
              width: md ? "100%" : 200,
              fontWeight: 600,
              "&:hover": {
                opacity: 0.7,
                background: `#D82B2B`,
              },
            }}
            onClick={onRemoveBrand}
          >
            Remove Brand
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeleteBrandPopup;

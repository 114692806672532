import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { getUserIngredientIds } from "../../actions/serverActions";
import AllTokensData from "./../../utils/AllTokens.json";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  table_row: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#fff",
    borderBottom: "1px solid #fff",
    padding: "0 10px",
    fontSize: 12,
  },
  table_head: {
    color: "#E0077D",
    border: "none",
    textAlign: "center",
    fontSize: 12,
  },
  table_data: { color: "#fff", border: "none", textAlign: "center" },
}));

const VaultIngredients = () => {
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const [userIngredients, setUserIngredients] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        let res = await getUserIngredientIds(
          "0x99d6bc65b2cc538696fc5a662aca79085a29fe86"
        );
        if (res) {
          let allIds = Object.keys(res.result);
          let quantities = Object.values(res.result);
          if (allIds?.length != 0) {
            let ingredients = allIds.map((singleId, i) => {
              let ingredientData = AllTokensData.find(
                (singleData) =>
                  singleData.tokenId.toString() === singleId.toString()
              );

              if (ingredientData) {
                let tempIngreData = {
                  tokenId: ingredientData.tokenId,
                  name: ingredientData.name,
                  quantity: quantities[i],
                };

                return tempIngreData;
              }
            });
            console.log("ingredients", ingredients);
            setUserIngredients([...ingredients]);
          }
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  
  return (
    <Box>
      <Typography fontWeight={600} fontSize={18} color={"#f9f9f9"}>
        Vault Ingredients
      </Typography>
      <br />
      <Box
        style={{
          border: "1px solid #0C0D12",
          backgroundColor: "#111214",
          borderRadius: 14,
          padding: 20,
          width: "100%",
        }}
      >
        <Typography fontWeight={600} fontSize={15} color={"#f9f9f9"} mb={1}>
          User Ingredients
        </Typography>
        <table className="table">
          <thead style={{ borderBottom: "1px solid #d1ff1a" }}>
            <tr className={classes.table_row}>
              <td
                style={{
                  fontSize: md && 8,
                  color: "#d1ff1a",
                  width: "10%",
                }}
                className={classes.table_head}
              >
                S.NO.
              </td>
              <td
                style={{ fontSize: md && 8, color: "#d1ff1a", width: "20%" }}
                className={classes.table_head}
              >
                NAME
              </td>
              <td
                style={{ fontSize: md && 8, color: "#d1ff1a", width: "20%" }}
                className={classes.table_head}
              >
                TOKENID
              </td>
              <td
                style={{
                  fontSize: md && 8,
                  color: "#d1ff1a",
                  width: "30%",
                  textAlign: "center",
                }}
                className={classes.table_head}
              >
                QUANTITY
              </td>
            </tr>
          </thead>
          <tbody>
            {userIngredients?.map((ingredient, i) => (
              <tr
                style={{
                  marginTop: 10,
                }}
                className={classes.table_row}
                key={i}
              >
                <td
                  className={classes.table_data}
                  style={{ width: "10%", paddingLeft: "20px" }}
                >
                  {i + 1}
                </td>
                <td className={classes.table_data} style={{ width: "20%" }}>
                  {ingredient.name}
                </td>
                <td className={classes.table_data} style={{ width: "20%" }}>
                  {ingredient.tokenId}
                </td>
                <td
                  className={classes.table_data}
                  style={{ width: "30%", textAlign: "center" }}
                >
                  {ingredient.quantity}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </Box>
  );
};

export default VaultIngredients;

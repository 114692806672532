import React, { useEffect, useState } from "react";
import AppAccordion from "./components/AppAccordion";
import { useTheme } from "@mui/styles";
import { Button, useMediaQuery } from "@mui/material";
import AddBrandPopup from "./components/AddBrandPopup";
import DeleteBrandPopup from "./components/DeleteBrandPopup";
import axios from "axios";

const AppDashboard = () => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const [openAddPopup, setOpenAddPopup] = useState(false);
  const [brandData, setBrandData] = useState(null);
  const [brandName, setBrandName] = useState(null);
  const [brandLogo, setBrandLogo] = useState(null);

  useEffect(() => {
    axios
      .get("https://superapp-backend.onerare.io/getAllRestaurants")
      .then(function (response) {
        console.log(response.data.result);
        setBrandData(response.data.result);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const onUpdateBrandName = async (minterAddress) => {
    const data = {
      name: brandName,
      minterAddress: minterAddress,
      superAdmin: "0xb2b0a3311742c977895a7f89f64a68c8b78c334a",
    };
    console.log("name", data);
    try {
      const response = await axios.post(
        "https://superapp-backend.onerare.io/setMinterName",
        data
      );
      console.log(`response `, response.data.result);
    } catch (error) {
      console.log(error);
    }
  };

  const onUpdateBrandImage = async (minterAddress) => {
    let formdata = new FormData();
    formdata.append("image", brandLogo);
    formdata.append("minterAddress", minterAddress);
    formdata.append("superAdmin", "0xb2b0a3311742c977895a7f89f64a68c8b78c334a");
    console.log("image", formdata);
    await axios
      .post("https://superapp-backend.onerare.io/setMinterImage", formdata)
      .then(function (response) {
        console.log(response.data.result);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <div className="ps-4 ps-md-2">
      <div
        className="pt-4 pb-3"
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1 className="heading" style={{ fontSize: md && 20 }}>
          Dashboard
        </h1>
        <Button
          style={{
            color: "#FF52FF",
            border: "1px solid #FF52FF",
            padding: md?"7px 15px":"10px 25px",
            borderRadius: "8px",
          }}
          onClick={() => setOpenAddPopup(true)}
        >
          Add New Brand
        </Button>
      </div>
      {brandData &&
        brandData.map(
          (brand, i) =>
            brand && (
              <AppAccordion
                key={i}
                id={brand._id}
                address={brand.address}
                brandName={brand?.name}
                brandImage={brand?.image}
                coupons={brand.coupons}
              />
            )
        )}
      <AddBrandPopup
        openAddPopup={openAddPopup}
        setOpenAddPopup={setOpenAddPopup}
        onUpdateBrandName={onUpdateBrandName}
        onUpdateBrandImage={onUpdateBrandImage}
        brandName={brandName}
        setBrandName={setBrandName}
        brandLogo={brandLogo}
        setBrandLogo={setBrandLogo}
      />
    </div>
  );
};

export default AppDashboard;

import * as React from "react";
import { Button, Input } from "@mui/material";
import { makeStyles } from "@mui/styles";
import SetAdmin from "./components/SetAdmin";
import FeatureCard from "./components/ContractCard";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  updateButton: {
    background: `linear-gradient(to right,#f9f9f9, #ffffff)`,
    color: "#000000",
    width: "fit-content",
    height: 40,
    textTransform: "none",
    borderRadius: 30,
    fontSize: 15,
    padding: "5px 20px 5px 20px",
    "&:hover": {
      background: "rgba(224, 7, 125, 0.7)",
    },
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: "fit-content",
      fontSize: 12,
    },
  },
  tableRow: {
    marginTop: 10,
  },
}));

const Contracts = () => {
  const classes = useStyles();

  return (
    <div>
      <div>
        <h1 className="heading">Contracts</h1>
        <h6 className="paragraph">Choose contract and features</h6>
      </div>
      <div className="row mt-2 ">
        <div className="col-md-4">
          <Link to="/contract/token">
            <FeatureCard name={"$ORARE Token"} />
          </Link>
        </div>
        <div className="col-md-4">
          <Link to="/contract/erc1155">
            <FeatureCard name={"ORare Nft"} />
          </Link>
        </div>{" "}
        <div className="col-md-4">
          <Link to="/contract/farming">
            <FeatureCard name={"ORareNftStaking"} />
          </Link>
        </div>
        <div className="col-md-4">
          <Link to="/contract/marketplace">
            <FeatureCard name={"Orare Marketplace"} />
          </Link>
        </div>
        <div className="col-md-4">
          <Link to="/contract/game">
            <FeatureCard name={"ORareGame"} />
          </Link>
        </div>{" "}
        <div className="col-md-4">
          <Link to="/contract/vault">
            <FeatureCard name={"NftRewardVault"} />
          </Link>
        </div>{" "}
      </div>
    </div>
  );
};

export default Contracts;

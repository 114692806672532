import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  getWithdrawRequests,
  processWithdrawOrare,
} from "../actions/serverActions";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useMetamask } from "../useMetamask";

const useStyles = makeStyles((theme) => ({
  table_row: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#fff",
    borderBottom: "1px solid #fff",
    padding: "0 10px",
    fontSize: 12,
  },
  table_head: {
    color: "#E0077D",
    border: "none",
    textAlign: "center",
    fontSize: 12,
  },
  table_data: { color: "#fff", border: "none", textAlign: "center" },
}));

const WithdrawRequests = () => {
  const classes = useStyles();
  const params = useParams();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const [requests, setRequests] = useState([]);
  const { accountSC } = useMetamask();

  useEffect(() => {
    getRequests();
  }, []);

  const getRequests = async () => {
    await getWithdrawRequests()
      .then((res) => setRequests(res.result))
      .catch((err) => console.log(err));
  };

  const onApproveRequest = async (withdrawId, adminAddress) => {
    await processWithdrawOrare(withdrawId, adminAddress)
      .then(() => getRequests())
      .catch((err) => console.log(err));
  };

  return (
    <Box>
      {" "}
      <Typography fontWeight={600} fontSize={18} color={"#f9f9f9"}>
        Withdrawals Requests
      </Typography>
      <Typography fontWeight={300} fontSize={12} color={"#e5e5e5"}>
        {params.id}
      </Typography>
      <br />
      <Box
        style={{
          border: "1px solid #0C0D12",
          backgroundColor: "#111214",
          borderRadius: 14,
          padding: 20,
          width: "100%",
        }}
      >
        <Typography fontWeight={600} fontSize={15} color={"#f9f9f9"} mb={1}>
          {/* Deposit - Withdrawals History */}
        </Typography>
        <table className="table">
          <thead style={{ borderBottom: "1px solid #d1ff1a" }}>
            <tr className={classes.table_row}>
              <td
                style={{
                  fontSize: md && 8,
                  color: "#d1ff1a",
                  width: "10%",
                }}
                className={classes.table_head}
              >
                S.NO.
              </td>
              <td
                style={{ fontSize: md && 8, color: "#d1ff1a", width: "30%" }}
                className={classes.table_head}
              >
                User Address
              </td>
              <td
                style={{ fontSize: md && 8, color: "#d1ff1a", width: "20%" }}
                className={classes.table_head}
              >
                AMOUNT
              </td>
              <td
                style={{
                  fontSize: md && 8,
                  color: "#d1ff1a",
                  width: "20%",
                  textAlign: "center",
                }}
                className={classes.table_head}
              >
                Status
              </td>
              <td
                style={{
                  fontSize: md && 8,
                  color: "#d1ff1a",
                  width: "20%",
                  textAlign: "center",
                }}
                className={classes.table_head}
              >
                ACTION
              </td>
            </tr>
          </thead>
          <tbody>
            {requests?.map((req, i) => (
              <tr
                style={{
                  marginTop: 10,
                }}
                className={classes.table_row}
                key={i}
              >
                <td
                  className={classes.table_data}
                  style={{ width: "10%", paddingLeft: "10px" }}
                >
                  {i + 1}
                </td>
                <td className={classes.table_data} style={{ width: "30%" }}>
                  {req.userAddress}
                </td>
                <td className={classes.table_data} style={{ width: "20%" }}>
                  {req.amount}
                </td>
                <td
                  className={classes.table_data}
                  style={{ width: "20%", textAlign: "center" }}
                >
                  {req.status}
                </td>
                <td
                  className={classes.table_data}
                  style={{ width: "20%", textAlign: "center" }}
                >
                  <button
                    style={{
                      borderRadius: 18,
                      fontSize: 14,
                      backgroundColor: "#d1ff1a",
                      color: "black",
                      fontWeight: 600,
                      padding: "7px 20px",
                      marginBottom: 10,
                      border: "none",
                    }}
                    onClick={() => onApproveRequest(req._id, accountSC)}
                  >
                    Approve
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </Box>
  );
};

export default WithdrawRequests;

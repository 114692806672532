import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getCouponUsers } from "../../actions/serverActions";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { West } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  table_row: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#fff",
    borderBottom: "1px solid #fff",
    padding: "0 10px",
    fontSize: 12,
  },
  table_row2: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#fff",
    borderBottom: "1px solid #fff",
    padding: "0 10px",
    fontSize: 12,
    "&:hover": {
      backgroundColor: "#212121",
    },
  },
  table_head: {
    color: "#E0077D",
    border: "none",
    textAlign: "center",
    fontSize: 12,
  },
  table_data: { color: "#fff", border: "none", textAlign: "center" },
}));

const CouponUsersDetails = () => {
  const classes = useStyles();
  const { id } = useParams();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const [couponUsers, setCouponUsers] = useState([]);
  const [couponApplied, setCouponApplied] = useState(0);
  const [reward1Claimed, setReward1Claimed] = useState(0);
  const [reward2Claimed, setReward2Claimed] = useState(0);

  useEffect(() => {
    (async () => {
      await getCouponUsers(id)
        .then((res) => setCouponUsers(res.result))
        .catch((err) => console.log(err));
    })();
  }, [id]);

  useEffect(() => {
    const couponApplied = couponUsers.filter(
      (user) => user.questStatus === 1
    ).length;
    setCouponApplied(couponApplied);
    const reward1 = couponUsers.filter((user) => user.questStatus === 2).length;
    setReward1Claimed(reward1);
    const reward2 = couponUsers.filter((user) => user.questStatus === 3).length;
    setReward2Claimed(reward2);
  }, [couponUsers]);

  return (
    <Box>
      <Box
        my={2}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Link
          to="/coupons"
          style={{
            width: 100,
            fontWeight: 600,
            fontSize: 16,
            color: "#f9f9f9",
            background: "#212121",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "3px",
            padding: "7px 10px",
            borderRadius: "12px",
          }}
        >
          <West color="red" /> BACK
        </Link>
        <Box
          sx={{
            height: 45,
            background: "#212121",
            border: "1px solid #000000",
            color: "#000",
            borderRadius: "14px",
            padding: "0 15px",
            fontWeight: 500,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            textTransform: "none",
            whiteSpace: "nowrap",
            gap: "10px",
          }}
        >
          <Typography
            variant=""
            style={{
              height: "65%",
              padding: "0 10px 0 0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRight: "1px solid #d1ff1a",
            }}
          >
            COUPON APPLIED : {couponApplied}
          </Typography>
          <Typography
            variant=""
            style={{
              height: "65%",
              padding: "0 10px 0 0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRight: "1px solid #d1ff1a",
            }}
          >
            REWARD 1 CLAIMED : {reward1Claimed}
          </Typography>
          <Typography
            variant=""
            style={{
              height: "65%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            REWARD 2 CLAIMED : {reward2Claimed}
          </Typography>
        </Box>
      </Box>
      <Box
        style={{
          border: "1px solid #0C0D12",
          backgroundColor: "#111214",
          borderRadius: 14,
          padding: 20,
          width: "100%",
        }}
      >
        <table className="table">
          <thead style={{ borderBottom: "1px solid #d1ff1a" }}>
            <tr className={classes.table_row}>
              <td
                style={{
                  fontSize: md && 8,
                  color: "#d1ff1a",
                  width: "10%",
                }}
                className={classes.table_head}
              >
                S.NO.
              </td>
              <td
                style={{ fontSize: md && 8, color: "#d1ff1a", width: "30%" }}
                className={classes.table_head}
              >
                EMAIL
              </td>
              <td
                style={{ fontSize: md && 8, color: "#d1ff1a", width: "20%" }}
                className={classes.table_head}
              >
                PHONE
              </td>
              <td
                style={{
                  fontSize: md && 8,
                  color: "#d1ff1a",
                  width: "20%",
                  textAlign: "center",
                }}
                className={classes.table_head}
              >
                FREE GAMES
              </td>
              <td
                style={{
                  fontSize: md && 8,
                  color: "#d1ff1a",
                  width: "20%",
                  textAlign: "center",
                }}
                className={classes.table_head}
              >
                STEP 1
              </td>
              <td
                style={{
                  fontSize: md && 8,
                  color: "#d1ff1a",
                  width: "20%",
                  textAlign: "center",
                }}
                className={classes.table_head}
              >
                STEP 2
              </td>
              <td
                style={{
                  fontSize: md && 8,
                  color: "#d1ff1a",
                  width: "20%",
                  textAlign: "center",
                }}
                className={classes.table_head}
              >
                STATUS
              </td>
              <td
                style={{
                  fontSize: md && 8,
                  color: "#d1ff1a",
                  width: "15%",
                  textAlign: "center",
                }}
                className={classes.table_head}
              ></td>
            </tr>
          </thead>
          <tbody>
            {couponUsers?.map((user, i) => (
              <tr
                key={i}
                style={{
                  paddingTop: 5,
                  cursor: "pointer",
                }}
                className={classes.table_row2}
              >
                <td
                  className={classes.table_data}
                  style={{ width: "10%", paddingLeft: "10px" }}
                >
                  {i + 1}
                </td>
                <td className={classes.table_data} style={{ width: "30%" }}>
                  {user.email}
                </td>
                <td
                  className={classes.table_data}
                  style={{ width: "20%", textAlign: "center" }}
                >
                  {user.phone}
                </td>
                <td className={classes.table_data} style={{ width: "20%" }}>
                  {user.freeFTWGames}
                </td>
                <td className={classes.table_data} style={{ width: "20%" }}>
                  {user.questArray[0] + user.questArray[1]}/2
                </td>
                <td className={classes.table_data} style={{ width: "20%" }}>
                  {user.questArray
                    ?.slice(2, 8)
                    ?.reduce((accumulator, currentValue) => {
                      return accumulator + currentValue;
                    }, 0)}
                  /6
                </td>
                <td className={classes.table_data} style={{ width: "20%" }}>
                  {user.questStatus === 0
                    ? "Coupon not applied"
                    : user.questStatus === 1
                    ? "Coupon Applied"
                    : user.questStatus === 2
                    ? "Reward 1 claimed"
                    : "Reward 2 claimed"}
                </td>
                <td className={classes.table_data} style={{ width: "15%" }}>
                  <Link to={"/users/" + user.address}>
                    <Button
                      variant="contained"
                      style={{
                        borderRadius: 10,
                        fontSize: 12,
                        backgroundColor: "#C7C7C7",
                        color: "black",
                        fontWeight: 600,
                      }}
                    >
                      View history
                                         </Button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </Box>
  );
};
export default CouponUsersDetails;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAllCoupons } from "../../actions/serverActions";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  table_row: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#fff",
    borderBottom: "1px solid #fff",
    padding: "0 10px",
    fontSize: 12,
  },
  table_row2: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#fff",
    borderBottom: "1px solid #fff",
    padding: "0 10px",
    fontSize: 12,
    "&:hover": {
      backgroundColor: "#212121",
    },
  },
  table_head: {
    color: "#E0077D",
    border: "none",
    textAlign: "center",
    fontSize: 12,
  },
  table_data: { color: "#fff", border: "none", textAlign: "center" },
}));

const Coupons = () => {
  const classes = useStyles();
  const params = useParams();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [coupons, setCoupons] = useState([]);
  const [couponCode, setCouponCode] = useState("");
  const [popup, showPopup] = useState(false);

  useEffect(() => {
    (async () => {
      await getAllCoupons()
        .then((res) => setCoupons(res.result))
        .catch((err) => console.log(err));
    })();
  }, []);

  return (
    <Box>
      <Typography fontWeight={600} fontSize={18} color={"#f9f9f9"}>
        ALL COUPONS
      </Typography>
      <br />
      <Box
        style={{
          border: "1px solid #0C0D12",
          backgroundColor: "#111214",
          borderRadius: 14,
          padding: 20,
          width: "100%",
        }}
      >
        <table className="table">
          <thead style={{ borderBottom: "1px solid #d1ff1a" }}>
            <tr className={classes.table_row}>
              <td
                style={{
                  fontSize: md && 8,
                  color: "#d1ff1a",
                  width: "10%",
                }}
                className={classes.table_head}
              >
                S.NO.
              </td>
              <td
                style={{ fontSize: md && 8, color: "#d1ff1a", width: "30%" }}
                className={classes.table_head}
              >
                COUPON CODE
              </td>
              <td
                style={{
                  fontSize: md && 8,
                  color: "#d1ff1a",
                  width: "20%",
                  textAlign: "center",
                }}
                className={classes.table_head}
              >
                USAGE
              </td>

              <td
                style={{ fontSize: md && 8, color: "#d1ff1a", width: "20%" }}
                className={classes.table_head}
              >
                MIN. POINTS
              </td>

              <td
                style={{
                  fontSize: md && 8,
                  color: "#d1ff1a",
                  width: "20%",
                  textAlign: "center",
                }}
                className={classes.table_head}
              >
                REWARDS
              </td>
              <td
                style={{
                  fontSize: md && 8,
                  color: "#d1ff1a",
                  width: "20%",
                  textAlign: "center",
                }}
                className={classes.table_head}
              >
                EXPIRY
              </td>
            </tr>
          </thead>
          <tbody>
            {coupons?.map((coupon, i) => (
              <tr
                key={i}
                style={{
                  paddingTop: 5,
                  cursor: "pointer",
                }}
                className={classes.table_row2}
                onClick={() => navigate(`/coupons/${coupon.code}`)}
              >
                <td
                  className={classes.table_data}
                  style={{ width: "10%", paddingLeft: "10px" }}
                >
                  {i + 1}
                </td>
                <td className={classes.table_data} style={{ width: "30%" }}>
                  {coupon.code}
                </td>
                <td className={classes.table_data} style={{ width: "20%" }}>
                  {coupon.timesRedeemed}/{coupon.maxLimit}
                </td>
                <td
                  className={classes.table_data}
                  style={{ width: "20%", textAlign: "center" }}
                >
                  {coupon.minPoints}
                </td>

                <td className={classes.table_data} style={{ width: "20%" }}>
                  {coupon.reward1?.map((reward, i) => (
                    <p
                      style={{
                        width: "100%",
                        maxWidth: 80,
                        margin: "0 auto",
                        textAlign: "left",
                      }}
                    >
                      {`${i + 1} : ${reward.amount} ${reward.type}`}
                      <br />
                    </p>
                  ))}
                  {coupon.reward2?.map(
                    (reward, i) =>
                      reward.type !== null && (
                        <p
                          style={{
                            width: "100%",
                            maxWidth: 80,
                            margin: "0 auto",
                            textAlign: "left",
                          }}
                        >
                          {`${i + coupon.reward1?.length + 1} : ${
                            reward.amount
                          } ${reward.type}`}
                          <br />
                        </p>
                      )
                  )}
                </td>
                <td className={classes.table_data} style={{ width: "20%" }}>
                  {new Date(coupon.expiryDate)?.toLocaleDateString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </Box>
  );
};

export default Coupons;

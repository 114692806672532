import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useState } from "react";
import { Button, Grid, useMediaQuery, useTheme } from "@mui/material";
import { createNewDish } from "../../actions/smartActions";

function ReleaseNew() {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const [tokenURI, setTokenURI] = useState("");
  const [ingredients, setIngredients] = useState("");
  const [quantities, setQuantities] = useState("");
  const [error, setError] = useState("");

  const handleDishCreate = async () => {
    var numIngr = ingredients.split(",").map(function (item) {
      return parseInt(item, 10);
    });
    var numQty = quantities.split(",").map(function (item) {
      return parseInt(item, 10);
    });

    console.log(numIngr);
    console.log(numQty);
    console.log(tokenURI.length);
    let recipe_ = {
      ingredients: numIngr,
      quantity: numQty,
    };
    console.log(tokenURI, recipe_);
    console.log(
      tokenURI.length > 0 &&
        numIngr.length === numQty.length &&
        numIngr.length > 0 &&
        numQty.length > 0
    );
    if (
      tokenURI.length > 0 &&
      numIngr.length === numQty.length &&
      numIngr.length > 0 &&
      numQty.length > 0
    ) {
      let response = await createNewDish(tokenURI, recipe_);
      console.log(response);

      if (response) {
        window.location.reload();
      }
    } else {
      setError("There is an error in your data.");
    }
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#212121",
          padding: md ? "5%" : "25px",
          marginLeft: md && "10px",
          marginTop: "25px",
        }}
      >
        <Typography
          variant="h4"
          textAlign="left"
          fontWeight="bold"
          fontSize={md && 20}
        >
          Create New Dish
        </Typography>
        <small id="emailHelp" class="form-text text-muted">
          Use data from NFTs excel sheet of OneRare here
        </small>
        <div className="mt-3">
          <div className="form-group">
            <input
              type="text"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter IPFS URL"
              style={{
                marginTop: 5,
                background: "transparent",
                border: "none",
                borderBottom: "2px solid #00ffff",
              }}
              onChange={(e) => setTokenURI(e.target.value)}
            />
          </div>
          <div className="form-group mt-4">
            <input
              type="text"
              class="form-control"
              id="exampleInputPassword1"
              placeholder="Enter Ingredients Array"
              style={{
                marginTop: 5,
                background: "transparent",
                border: "none",
                borderBottom: "2px solid #00ffff",
              }}
              onChange={(e) => setIngredients(e.target.value)}
            />
          </div>
          <div className="form-group mt-4">
            <input
              type="text"
              class="form-control"
              id="exampleInputPassword1"
              placeholder="Enter Ingredients Array"
              style={{
                marginTop: 5,
                background: "transparent",
                border: "none",
                borderBottom: "2px solid #00ffff",
              }}
              onChange={(e) => setQuantities(e.target.value)}
            />
          </div>
          <p style={{ color: "red" }}>{error}</p>
        </div>
        <Button
          sx={{
            background: `#D1FF1A`,
            color: "#000",
            width: md ? "100%" : 200,
            marginTop: 3,
            textTransform: "none",
            borderRadius: 20,
            fontSize: 14,
            padding: "10px 20px",
            cursor: "pointer",
            fontWeight: 600,
            "&:hover": {
              opacity: 0.7,
              background: `#D1FF1A`,
            },
          }}
          onClick={handleDishCreate}
        >
          Create New Dish
        </Button>
      </Box>
    </>
  );
}
export default ReleaseNew;

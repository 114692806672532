import { makeStyles } from "@mui/styles";
import {
  Agriculture,
  CardGiftcard,
  CurrencyExchange,
  Fastfood,
  Science,
  Storefront,
} from "@mui/icons-material";

import {
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: 32,
    color: "#fff",
  },
}));

const AnalyticsTopSection = ({
  labFees,
  dishesCooked,
  marketVol,

  tokensStaked,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      style={{
        border: "1px solid #0C0D12",
        backgroundColor: "#111214",
        borderRadius: 14,
        padding: 20,
        width: "auto",
      }}
      mt={3}
      display={md ? "grid" : "flex"}
      justifyContent={"space-between"}
      gridTemplateColumns={"1fr 1fr"}
      gap="10px"
    >
      <Box
        display={"flex"}
        justifyContent={md ? "flex-start" : "space-between"}
        gap="5px"
        style={{ borderRight: md && "1px solid #616161" }}
      >
        <IconButton
          color="success"
          style={{
            backgroundColor: "#36393E",
            height: md ? 30 : 40,
            width: md ? 30 : 40,
          }}
        >
          <Storefront />
        </IconButton>
        <Box ml={2}>
          <Typography
            fontWeight={300}
            fontSize={md ? 10 : 12}
            color={"#e5e5e5"}
          >
            Market sales ($ORARE)
          </Typography>
          <Typography fontWeight={600} fontSize={md ? 14 : 18} color={"#fff"}>
            {marketVol && marketVol.toLocaleString()}
          </Typography>
        </Box>
      </Box>
      {!md && (
        <Box style={{ borderRight: "1px solid #616161", width: 5 }}></Box>
      )}
      <Box
        pl={md && 1}
        display={"flex"}
        justifyContent={md ? "flex-start" : "space-between"}
        gap="5px"
      >
        <IconButton
          color="success"
          style={{
            backgroundColor: "#36393E",
            height: md ? 30 : 40,
            width: md ? 30 : 40,
          }}
        >
          <CurrencyExchange />
        </IconButton>
        <Box ml={2}>
          <Typography
            fontWeight={300}
            fontSize={md ? 10 : 12}
            color={"#e5e5e5"}
          >
            Market fees($ORARE)
          </Typography>
          <Typography fontWeight={600} fontSize={md ? 14 : 18} color={"#fff"}>
            {marketVol && (parseFloat(marketVol) * 0.05).toLocaleString()}
          </Typography>
        </Box>
      </Box>
      {!md && (
        <Box style={{ borderRight: "1px solid #616161", width: 5 }}></Box>
      )}
      <Box
        display={"flex"}
        justifyContent={md ? "flex-start" : "space-between"}
        gap="5px"
        style={{ borderRight: md && "1px solid #616161" }}
      >
        <IconButton
          color="success"
          style={{
            backgroundColor: "#36393E",
            height: md ? 30 : 40,
            width: md ? 30 : 40,
          }}
        >
          <Science />
        </IconButton>
        <Box ml={2}>
          <Typography
            fontWeight={300}
            fontSize={md ? 10 : 12}
            color={"#e5e5e5"}
          >
            Lab fees($ORARE)
          </Typography>
          <Typography fontWeight={600} fontSize={md ? 14 : 18} color={"#fff"}>
            {labFees}
          </Typography>
        </Box>
      </Box>
      {!md && (
        <Box style={{ borderRight: "1px solid #616161", width: 5 }}></Box>
      )}
      <Box
        pl={md && 1}
        display={"flex"}
        justifyContent={md ? "flex-start" : "space-between"}
        gap="5px"
      >
        <IconButton
          color="success"
          style={{
            backgroundColor: "#36393E",
            height: md ? 30 : 40,
            width: md ? 30 : 40,
          }}
        >
          <Agriculture />
        </IconButton>
        <Box ml={2}>
          <Typography
            fontWeight={300}
            fontSize={md ? 10 : 12}
            color={"#e5e5e5"}
          >
            Total Token Staked
          </Typography>
          <Typography fontWeight={600} fontSize={md ? 14 : 18} color={"#fff"}>
            {tokensStaked && tokensStaked.toLocaleString()}
          </Typography>
        </Box>
      </Box>

      {!md && (
        <Box style={{ borderRight: "1px solid #616161", width: 5 }}></Box>
      )}
      <Box
        display={"flex"}
        justifyContent={md ? "flex-start" : "space-between"}
        gap="5px"
      >
        <IconButton
          color="success"
          style={{
            backgroundColor: "#36393E",
            height: md ? 30 : 40,
            width: md ? 30 : 40,
          }}
        >
          <Fastfood />
        </IconButton>
        <Box ml={2}>
          <Typography
            fontWeight={300}
            fontSize={md ? 10 : 12}
            color={"#e5e5e5"}
          >
            Dish cooked
          </Typography>
          <Typography fontWeight={600} fontSize={md ? 14 : 18} color={"#fff"}>
            {dishesCooked}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AnalyticsTopSection;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUserDepositWithdraw } from "../../actions/serverActions";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  table_row: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "#fff",
    borderBottom: "1px solid #fff",
    padding: "0 10px",
    fontSize: 12,
  },
  table_head: {
    color: "#E0077D",
    border: "none",
    textAlign: "center",
    fontSize: 12,
  },
  table_data: { color: "#fff", border: "none", textAlign: "center" },
}));

const UserDetails = () => {
  const classes = useStyles();
  const params = useParams();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const [details, setDetails] = useState([]);

  useEffect(() => {
    (async () => {
      await getUserDepositWithdraw(params.id)
        .then((res) => setDetails(res.result))
        .catch((err) => console.log(err));
    })();
  }, [details]);

  return (
    <Box>
      {" "}
      <Typography fontWeight={600} fontSize={18} color={"#f9f9f9"}>
        User Details
      </Typography>
      <Typography fontWeight={300} fontSize={12} color={"#e5e5e5"}>
        {params.id}
      </Typography>
      <br />
      <Box
        style={{
          border: "1px solid #0C0D12",
          backgroundColor: "#111214",
          borderRadius: 14,
          padding: 20,
          width: "100%",
        }}
      >
        <Typography fontWeight={600} fontSize={15} color={"#f9f9f9"} mb={1}>
          Deposit - Withdrawals History
        </Typography>
        <table className="table">
          <thead style={{ borderBottom: "1px solid #d1ff1a" }}>
            <tr className={classes.table_row}>
              <td
                style={{
                  fontSize: md && 8,
                  color: "#d1ff1a",
                  width: "10%",
                }}
                className={classes.table_head}
              >
                S.NO.
              </td>
              <td
                style={{ fontSize: md && 8, color: "#d1ff1a", width: "20%" }}
                className={classes.table_head}
              >
                TXN TYPE
              </td>
              <td
                style={{ fontSize: md && 8, color: "#d1ff1a", width: "20%" }}
                className={classes.table_head}
              >
                AMOUNT
              </td>
              <td
                style={{
                  fontSize: md && 8,
                  color: "#d1ff1a",
                  width: "30%",
                  textAlign: "center",
                }}
                className={classes.table_head}
              >
                TIME
              </td>
              <td
                style={{
                  fontSize: md && 8,
                  color: "#d1ff1a",
                  width: "20%",
                  textAlign: "center",
                }}
                className={classes.table_head}
              >
                ACTION
              </td>
            </tr>
          </thead>
          <tbody>
            {details?.map((user, i) => (
              <tr
                style={{
                  marginTop: 10,
                }}
                className={classes.table_row}
                key={i}
              >
                <td
                  className={classes.table_data}
                  style={{ width: "10%", paddingLeft: "20px" }}
                >
                  {i + 1}
                </td>
                <td className={classes.table_data} style={{ width: "20%" }}>
                  {user.Activity}
                </td>
                <td className={classes.table_data} style={{ width: "20%" }}>
                  {user.Amount}
                </td>
                <td
                  className={classes.table_data}
                  style={{ width: "30%", textAlign: "center" }}
                >
                  {new Date(user.Date.toString()).toLocaleString()}
                </td>
                <td
                  className={classes.table_data}
                  style={{ width: "20%", textAlign: "center" }}
                >
                  <a
                    href={"https://polygonscan.com/tx/" + user.txHash}
                    target="_blank"
                    style={{
                      borderRadius: 18,
                      fontSize: 14,
                      backgroundColor: "#d1ff1a",
                      color: "black",
                      fontWeight: 600,
                      padding: "7px 15px",
                      marginBottom: 15,
                    }}
                  >
                    View Xx
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>
    </Box>
  );
};

export default UserDetails;

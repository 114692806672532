import { makeStyles } from "@mui/styles";
import {
  ArrowCircleRight,
  DoubleArrow,
  Science,
  Store,
  Wallet,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import { getOrareBalanceByAddress } from "../../actions/smartActions";
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { apolloClient } from "../..";
import { GetAdminStatsData } from "../../queries/graphQueries";
import AnalyticsTopSection from "./AnalyticsTopSection";
import {
  getAnalyticsDataByTelegramId,
  getAnalyticsDataReferral,
  getAnalyticsDataTasks,
  getAnalyticsDataTon,
  getAnalyticsDataWallet,
  getDBAdminStats,
  getDBOrareBalance,
  getTotalMarketSalesVolume,
} from "../../actions/serverActions";
import AnalyticsWalletBalances from "./AnalyticsWalletBalances";
import MarketActivityChart from "./components/MarketActivityChart";
import SignUpChart from "./components/SignUpChart";
import ClaimIngreChart from "./components/ClaimIngreChart";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "#0C0D11",
    marginTop: 20,
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    width: "100%",
    minHeight: 200,
    height: "100%",
    border: "1px solid #1b1d24",
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: 14,
    "&:hover": {
      boxShadow: "0px 24px 33px -9px #0000005C",
    },

    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
    },
  },
}));

const Analytics = () => {
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const [stats, setStats] = useState(null);
  const [poolDBBalance, setPoolDBBalance] = useState(0);
  const [dbStats, setDbStats] = useState(null);
  const [marketVol, setMarketVol] = useState(0);
  const [hotBalace, setHotBalance] = useState(null);
  const [tonData, setTonData] = useState(null);
  const [tasksData, setTasksData] = useState(null);
  const [referralsData, setReferralsData] = useState(null);
  const [totalWallets, setTotalWallets] = useState(null);
  const [tgUserId, setTgUserId] = useState("");
  const [userDetails, setUserDetails] = useState(null);

  let hot_wallet = "0x8eb738f2b14bc009164bc5aac9f5f9594e57aba1";
  let cold_wallet = "0x9b4b8fac99695dd40a616a47290f2fb3b5ae53cd";
  let v2_deposit_wallet = "0x9f52a87F09054864FD5E840BA03689db078Ab331";

  // Get balance of pool
  useEffect(() => {
    async function asyncFn() {
      // ORARE Balance in Database
      let resBalance = await getDBOrareBalance();
      setPoolDBBalance(resBalance);

      let resStats = await getDBAdminStats();
      setDbStats(resStats);

      let resSales = await getTotalMarketSalesVolume();
      setMarketVol(resSales);

      let resTonData = await getAnalyticsDataTon();
      setTonData(resTonData);

      let resTasksData = await getAnalyticsDataTasks();
      setTasksData(resTasksData);

      let resReferralsData = await getAnalyticsDataReferral();
      setReferralsData(resReferralsData);

      let resTotalWallets = await getAnalyticsDataWallet();
      setTotalWallets(resTotalWallets);
    }
    asyncFn();
  }, []);

  // Get orare balance from blockchain of admin wallets
  useEffect(() => {
    async function asyncFn() {
      let resHot = await getOrareBalanceByAddress(hot_wallet);
      let resCold = await getOrareBalanceByAddress(cold_wallet);
      let resV2Cold = await getOrareBalanceByAddress(v2_deposit_wallet);

      setHotBalance(resHot + resCold + resV2Cold);
    }
    asyncFn();
  }, []);

  useEffect(() => {
    (async () => {
      const { loading, data, error } = await apolloClient.query({
        query: GetAdminStatsData,
      });
      if (!loading && !error) {
        return setStats(data.statsEntity);
      } else {
        console.log(error);
      }
    })();
  }, []);

  const onSearchUserData = async () => {
    await getAnalyticsDataByTelegramId(tgUserId)
      .then((res) => setUserDetails(res))
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <div className="pt-4 pt-md-2 d-flex justify-content-between">
        <Typography fontWeight={600} fontSize={18} color={"#f9f9f9"}>
          Analytics
        </Typography>
      </div>

      {dbStats && poolDBBalance && hotBalace && (
        <Grid container mt={1} spacing={2}>
          <Grid item xs={12} md={4}>
            <Box
              className={classes.card}
              style={{
                backgroundImage: "linear-gradient(to right,#DADADA, #bdbdbd)",
              }}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  {/* <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    mr={1}
                    style={{
                      backgroundColor: "#505961",
                      height: 36,
                      width: 36,
                      borderRadius: 12,
                    }}
                  >
                    <Store />
                  </Box> */}
                  <Typography fontWeight={600} fontSize={18} color={"#000000"}>
                    USERS TON DATA
                  </Typography>
                </Box>
              </Box>
              <Box mt={3}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    border: "1px solid #000",
                  }}
                >
                  <Typography
                    style={{
                      color: "#000",
                      padding: "0 15px",
                      borderRight: "1px solid #000",
                      width: "100%",
                    }}
                  >
                    TON balance
                  </Typography>
                  <Typography
                    style={{
                      color: "#000",
                      padding: "0 15px",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    No. Of Users
                  </Typography>
                </Box>
                <Box
                  style={{
                    border: "1px solid #000",
                    marginTop: "5px",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #000",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        borderRight: "1px solid #000",
                        width: "100%",
                      }}
                    >
                      1 cent
                    </Typography>
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {tonData?.tonFor1cent}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #000",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        borderRight: "1px solid #000",
                        width: "100%",
                      }}
                    >
                      50 cent
                    </Typography>
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {tonData?.tonFor50cent}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        borderRight: "1px solid #000",
                        width: "100%",
                      }}
                    >
                      1 TON
                    </Typography>
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {tonData?.tonFor100cent}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        borderRight: "1px solid #000",
                        width: "100%",
                      }}
                    >
                      2 TON
                    </Typography>
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {tonData?.tonFor200cent}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        borderRight: "1px solid #000",
                        width: "100%",
                      }}
                    >
                      5 TON
                    </Typography>
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {tonData?.tonFor500cent}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                mt={3}
                style={{
                  color: "#000",
                  padding: "0 15px",
                  border: "1px solid #000",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 600,
                }}
              >
                WALLET CONNECTED: {totalWallets}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              className={classes.card}
              style={{
                backgroundImage: "linear-gradient(to right,#DADADA, #bdbdbd)",
              }}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography fontWeight={600} fontSize={18} color={"#000000"}>
                    USERS TASKS DATA
                  </Typography>
                </Box>
              </Box>

              {/* tasks data  */}
              <Box mt={3}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    border: "1px solid #000",
                  }}
                >
                  <Typography
                    style={{
                      color: "#000",
                      padding: "0 15px",
                      borderRight: "1px solid #000",
                      width: "100%",
                    }}
                  >
                    No. Of Tasks
                  </Typography>
                  <Typography
                    style={{
                      color: "#000",
                      padding: "0 15px",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    No. Of Users
                  </Typography>
                </Box>
                <Box
                  style={{
                    border: "1px solid #000",
                    marginTop: "5px",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #000",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        borderRight: "1px solid #000",
                        width: "100%",
                      }}
                    >
                      1
                    </Typography>
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {tasksData?.tasksFor1}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #000",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        borderRight: "1px solid #000",
                        width: "100%",
                      }}
                    >
                      2
                    </Typography>
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {tasksData?.tasksFor2}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #000",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        borderRight: "1px solid #000",
                        width: "100%",
                      }}
                    >
                      3
                    </Typography>
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {tasksData?.tasksFor3}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #000",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        borderRight: "1px solid #000",
                        width: "100%",
                      }}
                    >
                      4
                    </Typography>
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {tasksData?.tasksFor4}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #000",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        borderRight: "1px solid #000",
                        width: "100%",
                      }}
                    >
                      5
                    </Typography>
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {tasksData?.tasksFor5}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #000",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        borderRight: "1px solid #000",
                        width: "100%",
                      }}
                    >
                      6
                    </Typography>
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {tasksData?.tasksFor6}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #000",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        borderRight: "1px solid #000",
                        width: "100%",
                      }}
                    >
                      7
                    </Typography>
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {tasksData?.tasksFor7}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        borderRight: "1px solid #000",
                        width: "100%",
                      }}
                    >
                      8
                    </Typography>
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {tasksData?.tasksFor8}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              className={classes.card}
              style={{
                backgroundImage: "linear-gradient(to right,#DADADA, #bdbdbd)",
              }}
            >
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography fontWeight={600} fontSize={18} color={"#000000"}>
                    USERS REFERRALS DATA
                  </Typography>
                </Box>
              </Box>

              {/* referrals data  */}
              <Box mt={3}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    border: "1px solid #000",
                  }}
                >
                  <Typography
                    style={{
                      color: "#000",
                      padding: "0 15px",
                      borderRight: "1px solid #000",
                      width: "100%",
                    }}
                  >
                    No. Of Referrals
                  </Typography>
                  <Typography
                    style={{
                      color: "#000",
                      padding: "0 15px",
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    No. Of Users
                  </Typography>
                </Box>
                <Box
                  style={{
                    border: "1px solid #000",
                    marginTop: "5px",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #000",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        borderRight: "1px solid #000",
                        width: "100%",
                      }}
                    >
                      1
                    </Typography>
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {referralsData?.referralFor1}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #000",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        borderRight: "1px solid #000",
                        width: "100%",
                      }}
                    >
                      2
                    </Typography>
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {referralsData?.referralFor2}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #000",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        borderRight: "1px solid #000",
                        width: "100%",
                      }}
                    >
                      3
                    </Typography>
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {referralsData?.referralFor3}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #000",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        borderRight: "1px solid #000",
                        width: "100%",
                      }}
                    >
                      5
                    </Typography>
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {referralsData?.referralFor5}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #000",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        borderRight: "1px solid #000",
                        width: "100%",
                      }}
                    >
                      10
                    </Typography>
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {referralsData?.referralFor10}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #000",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        borderRight: "1px solid #000",
                        width: "100%",
                      }}
                    >
                      50
                    </Typography>
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {referralsData?.referralFor50}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #000",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        borderRight: "1px solid #000",
                        width: "100%",
                      }}
                    >
                      100
                    </Typography>
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {referralsData?.referralFor100}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #000",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        borderRight: "1px solid #000",
                        width: "100%",
                      }}
                    >
                      500
                    </Typography>
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {referralsData?.referralFor500}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      borderBottom: "1px solid #000",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        borderRight: "1px solid #000",
                        width: "100%",
                      }}
                    >
                      1000
                    </Typography>
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {referralsData?.referralFor1000}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        borderRight: "1px solid #000",
                        width: "100%",
                      }}
                    >
                      5000
                    </Typography>
                    <Typography
                      style={{
                        color: "#000",
                        padding: "0 15px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      {referralsData?.referralFor5000}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}

      <Box
        mt={5}
        className={classes.card}
        style={{
          backgroundImage: "linear-gradient(to right,#DADADA, #bdbdbd)",
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box
            width="100%"
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography fontWeight={600} fontSize={18} color={"#000000"}>
              GET USER DETAILS WITH TELEGRAM USER ID
            </Typography>

            <Box
              style={{
                background: "transparent",
                border: "1px solid #000",
                borderRadius: "8px",
                padding: "5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <input
                type="number"
                value={tgUserId}
                onChange={(e) => setTgUserId(e.target.value)}
                placeholder="Enter Telegram User ID"
                style={{
                  background: "transparent",
                  border: "none",
                  fontSize: 18,
                  color: "#000",
                  outline: "none",
                }}
              />
              <Button
                style={{
                  background: "#d1ff1a",
                  color: "#000",
                  fontWeight: 600,
                }}
                onClick={onSearchUserData}
              >
                SEARCH
              </Button>
            </Box>
          </Box>
        </Box>
        {userDetails?.totalReferrals && (
          <Box
            style={{
              border: "1px solid #000",
              marginTop: "10px",
              marginBottom: "15px",
            }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #000",
              }}
            >
              <Typography
                style={{
                  color: "#000",
                  padding: "0 15px",
                  borderRight: "1px solid #000",
                  width: "100%",
                }}
              >
                Total Referrals
              </Typography>
              <Typography
                style={{
                  color: "#000",
                  padding: "0 15px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {userDetails?.totalReferrals}
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #000",
              }}
            >
              <Typography
                style={{
                  color: "#000",
                  padding: "0 15px",
                  borderRight: "1px solid #000",
                  width: "100%",
                }}
              >
                TON Balance
              </Typography>
              <Typography
                style={{
                  color: "#000",
                  padding: "0 15px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {userDetails?.totalTON}
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #000",
              }}
            >
              <Typography
                style={{
                  color: "#000",
                  padding: "0 15px",
                  borderRight: "1px solid #000",
                  width: "100%",
                }}
              >
                Tap Score
              </Typography>
              <Typography
                style={{
                  color: "#000",
                  padding: "0 15px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {userDetails?.tapScore}
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #000",
              }}
            >
              <Typography
                style={{
                  color: "#000",
                  padding: "0 15px",
                  borderRight: "1px solid #000",
                  width: "100%",
                }}
              >
                League Level
              </Typography>
              <Typography
                style={{
                  color: "#000",
                  padding: "0 15px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {userDetails?.leagueLevel}
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #000",
              }}
            >
              <Typography
                style={{
                  color: "#000",
                  padding: "0 15px",
                  borderRight: "1px solid #000",
                  width: "100%",
                }}
              >
                Total Gobbls
              </Typography>
              <Typography
                style={{
                  color: "#000",
                  padding: "0 15px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {userDetails?.totalGobbls}
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                style={{
                  color: "#000",
                  padding: "0 15px",
                  borderRight: "1px solid #000",
                  width: "100%",
                }}
              >
                Referral Points
              </Typography>
              <Typography
                style={{
                  color: "#000",
                  padding: "0 15px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                {userDetails?.referralPoints}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>

      {dbStats && (
        <AnalyticsTopSection
          labFees={dbStats.totalLabFees}
          dishesCooked={dbStats?.totalDishesCooked}
          totalRewards={dbStats?.totalRewards}
          marketVol={marketVol}
          tokensStaked={dbStats?.totalTokensStaked}
        />
      )}

      <Grid container mb={6}>
        <Grid item md={12}>
          <MarketActivityChart />
        </Grid>
      </Grid>
      <Grid container mb={6} spacing={3}>
        <Grid item md={6}>
          <SignUpChart />
        </Grid>
        <Grid item md={6}>
          <ClaimIngreChart />
        </Grid>
      </Grid>

      <AnalyticsWalletBalances />
    </div>
  );
};

export default Analytics;

import * as React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";
import AnalyticsUserBalances from "../Analytics/AnalyticsUserBalances";
import AnalyticsActiveUsers from "../Analytics/AnalyticsActiveUsers";
import AnalyticsFTWarsUsers from "../Analytics/AnalyticsFTWarsUser";

const useStyles = makeStyles((theme) => ({
  tabText: {
    fontWeight: 900,
    textTransform: "none",
  },
  heading: {
    color: "#e5e5e5",
    fontSize: 24,
    fontWeight: 600,
    textAlign: "left",
  },
  background: {
    paddingTop: 30,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  divider: {
    width: 130,
    height: 3,
    background: "linear-gradient(to right, #e0077d, rgba(0, 0, 0, 0.4))",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 15,
    },
  },
  actionButton: {
    background: `linear-gradient(to bottom,#D9047C, #BF1088)`,
    color: "white",
    width: "fit-content",
    height: 40,
    textTransform: "none",
    borderRadius: 30,
    fontSize: 15,
    marginRight: 5,
    marginLeft: 5,
    display: "flex",
    alignItems: "center",
    padding: "5px 20px 5px 20px",
    "&:hover": {
      background: "rgba(224, 7, 125, 0.7)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "fit-content",
      fontSize: 12,
    },
  },

  headStyle: {
    width: 950,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 10,

    paddingBottom: 20,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "space-between",
    },
  },
  contentStyles: {
    width: 950,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    paddingTop: 10,
    paddingBottom: 20,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "space-between",
    },
  },
  subHeading: {
    color: "#ffffff",
    textTransform: "uppercase",
    textAlign: "center",
    marginBottom: 0,
    marginTop: 35,
    fontSize: 26,
    fontWeight: 400,
  },
}));

const Users = () => {
  const props = {
    backgroundColor: "black",
    color: "white",
  };

  return (
    <div className="pt-4 pt-md-2">
      <Typography fontWeight={600} fontSize={18} color={"#f9f9f9"}>
        Users
      </Typography>
      <AnalyticsUserBalances />
      <Grid container mb={6} spacing={2}>
        <Grid item md={6}>
          <AnalyticsActiveUsers />
        </Grid>
        <Grid item md={6}>
          <AnalyticsFTWarsUsers />
        </Grid>
      </Grid>
    </div>
  );
};

export default Users;

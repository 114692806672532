import { useState } from "react";
import { Box, Button, Input, Modal } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { CloseRounded } from "@mui/icons-material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

const attributes = [
  {
    trait_type: "Veg/Non-Veg",
    value: "Vegetarian",
  },
  {
    trait_type: "Vegan",
    value: "Vegan",
  },
  {
    trait_type: "Gluten-Free",
    value: "Gluten-Free",
  },
  {
    trait_type: "Category",
    value: "Farm Fresh",
  },
  {
    trait_type: "Processing",
    value: "Raw",
  },
  {
    trait_type: "Brand",
    value: "OneRare",
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 16,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
};
const useStyles = makeStyles((theme) => ({
  input: {
    margin: "4px 0",
    width: 400,
  },
}));
const CreateNft = ({ open, setOpen, handleOpen }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [image, setImage] = useState(null);
  const [category, setCategory] = useState("");
  // const [attribute, setAttribute] = useState("Veg/Non-Veg");
  const [veg, setVeg] = useState("");
  const [vegan, setVegan] = useState("");
  const [glutenFree, setGlutenFree] = useState("");
  const [categ, setCateg] = useState("");
  const [processing, setProcessing] = useState("");
  const [brand, setBrand] = useState("");

  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    setImage(e.target.files[0]);
  };

  const onCreateNft = (e) => {
    e.preventDefault();
    console.log("name", name);
    console.log("description", description);
    console.log("image", image);
    console.log("category", category);
    console.log("type", type);
    // console.log("attribute", attribute);
    handleClose();
    setName("");
    setDescription("");
    setImage("");
    setCategory("");
    setType("");
    // setAttribute("Veg/Non-Veg");
  };
  return (
    <Modal
      keepMounted
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      disableEnforceFocus
    >
      <Box sx={style}>
        {/* <a onClick={handleClose} className={classes.closeIcon}>
          <CloseRounded />
        </a> */}
        <h1>Create a NFT</h1>
        <br />
        <form onSubmit={onCreateNft} className="d-flex flex-column align-end">
          <Input
            placeholder="Select Image..."
            type="file"
            onChange={handleChange}
            className={classes.input}
          />
          <Input
            placeholder="Name"
            value={name || ""}
            onChange={(e) => setName(e.target.value)}
            className={classes.input}
          />
          <Input
            placeholder="Description"
            value={description || ""}
            onChange={(e) => setDescription(e.target.value)}
            className={classes.input}
          />
          <Input
            placeholder="Category"
            value={category || ""}
            onChange={(e) => setCategory(e.target.value)}
            className={classes.input}
          />
          <Input
            type="number"
            placeholder="type"
            value={type || ""}
            onChange={(e) => setType(e.target.value)}
            className={classes.input}
          />
          <Input
            placeholder="Veg/Non-Veg"
            value={veg || ""}
            onChange={(e) => setVeg(e.target.value)}
            className={classes.input}
          />
          <Input
            placeholder="Vegan"
            value={vegan || ""}
            onChange={(e) => setVegan(e.target.value)}
            className={classes.input}
          />
          <Input
            placeholder="Gluten-Free"
            value={glutenFree || ""}
            onChange={(e) => setGlutenFree(e.target.value)}
            className={classes.input}
          />
          <Input
            placeholder="Farm Fresh"
            value={categ || ""}
            onChange={(e) => setCateg(e.target.value)}
            className={classes.input}
          />
          <Input
            placeholder="Processing"
            value={processing || ""}
            onChange={(e) => setProcessing(e.target.value)}
            className={classes.input}
          />
          <Input
            placeholder="Brand"
            value={brand || ""}
            onChange={(e) => setBrand(e.target.value)}
            className={classes.input}
          />
          {/* <TextField
            select
            // label="Attribute"
            value={attribute || ""}
            onChange={(e) => setAttribute(e.target.value)}
            helperText="Please select the attribute"
            variant="standard"
            color="secondary"
            className={classes.input}
          >
            {attributes.map((attribute) => (
              <MenuItem key={attribute.trait_type} value={attribute.trait_type}>
                {attribute.value}
              </MenuItem>
            ))}
          </TextField> */}
          <br />
          <Button
            sx={{
              background: `#D1FF1A`,
              color: "#000000",
              width: "max-content",
              height: 40,
              margin: "0 auto",
              textTransform: "none",
              borderRadius: "30px",
              fontSize: 15,
              padding: "5px 20px 5px 20px",
              fontWeight:600,
              "&:hover": {
                opacity: 0.7,
                background: `#D1FF1A`,
              },
              cursor: "pointer",
              [theme.breakpoints.down("md")]: {
                width: "fit-content",
                fontSize: 12,
              },
            }}
            type="submit"
          >
            Create
          </Button>
        </form>
      </Box>
    </Modal>
  );
};
export default CreateNft;

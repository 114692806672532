import { Box, Button } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { useEffect, useState } from "react";
import { getUserAddress } from "../actions/web3Actions";
import { useMetamask } from "../useMetamask";
import { Wallet } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const ConnectButton = ({}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { accountSC, loginWithMetamask, logout } = useMetamask();

  const [loggedIn, setLoggedIn] = useState(null);

  const onLogout = async () => {
    await logout();

    navigate("/login");
  };
  return (
    <div>
      {!accountSC ? (
        <Button
          sx={{
            background: "#d1ff1a",
            border: "1px solid #000000",
            color: "#fff",
            paddingLeft: "15px",
            paddingRight: "15px",
            borderRadius: "20px",
            fontWeight: 500,
            textTransform: "none",
            [theme.breakpoints.down("sm")]: {
              marginRight: 0,
              marginLeft: "15px",
              width: 150,
              fontSize: 13,
            },
          }}
          variant="contained"
          onClick={loginWithMetamask}
        >
          Connect Wallet
        </Button>
      ) : (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Button
            sx={{
              background: "#212121",
              border: "1px solid #000000",
              color: "#000",
              borderRadius: 6,
              padding: "8px 15px",
              fontWeight: 500,
              display: "flex",
              justifyContent: "space-around",
              textTransform: "none",
              whiteSpace: "nowrap",
              [theme.breakpoints.down("sm")]: {
                width: "100%",
                fontSize: 9,
                justifyContent: "center",
                padding: "7px 12px",
              },
            }}
          >
            <Wallet />

            <span style={{ marginLeft: 5 }}>
              {accountSC && [...accountSC].splice(0, 4)} {"..."}{" "}
              {accountSC && accountSC.slice(-5)}
            </span>
          </Button>
          <Button
            sx={{
              background: "#d1ff1a",
              border: "1px solid #000000",
              color: "#000",
              borderRadius: 6,
              padding: "8px 15px",
              fontWeight: 500,
              display: "flex",
              justifyContent: "space-around",
              textTransform: "none",
              [theme.breakpoints.down("sm")]: {
                width: 40,
                fontSize: 10,
                padding: "7px 5px",
              },
            }}
            onClick={onLogout}
          >
            Logout
          </Button>
        </Box>
      )}
    </div>
  );
};

export default ConnectButton;

import React, { useState, useEffect } from "react";

import {
  Button,
  Typography,
  Box,
  Dialog,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Link } from "react-router-dom";
import { Close } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  background: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    zIndex: 10,
    display: "grid",
    placeItems: "center",
    background: "rgba(0,0,0,0.2)",
  },
  container: {
    width: "100%",
    height: "max-content",
    height: 400,
    maxWidth: 800,
    position: "relative",
    background: "#212121",
    border: "12px solid #D1FE1D",
    padding: 50,
    borderRadius: 4,
    [theme.breakpoints.down("md")]: {
      padding: "25px 5%",
      width: "100%",
      maxWidth: "95%",
      height: 350,
    },
    [theme.breakpoints.down("sm")]: {
      height: "max-content",
    },
  },

  heading: {
    color: "#000000",
    textAlign: "center",
    fontSize: 30,
    paddingBottom: 10,
    [theme.breakpoints.down("md")]: {
      fontSize: 22,
    },
  },
  para: {
    width: "90%",
    color: "#212121",
    textAlign: "center",
    fontSize: 13,
    fontWeight: 400,
    [theme.breakpoints.down("md")]: {
      fontSize: 13,
    },
  },

  reloadButton: {
    minWidth: 100,
    width: "fit-content",
    height: "40px",
    background: "transparent",
    border: "1.5px solid #212121",
    boxSizing: "border-box",
    borderRadius: "12px",
    fontSize: 13,

    color: "#212121",

    marginTop: 20,
    padding: "6px 30px 6px 30px",

    [theme.breakpoints.down("md")]: {
      padding: "5px 15px 5px 15px",
      fontSize: 15,
    },
  },
  svgImage: {
    width: "75%",
    maxHeight: 180,
    [theme.breakpoints.down("md")]: {
      width: "70%",
      margin: 15,
    },
  },
}));

const TxPopup = ({ txCase, resetPopup, showPopup }) => {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      onClose={resetPopup}
      open={showPopup}
      maxWidth="md"
      fullWidth={true}
      style={{
        marginLeft: "-15px",
        minHeight: 350,
        maxWidth: 850,
        margin: "0 auto",
      }}
    >
      <Box
        border="10px solid #D1FF1A"
        px={!sm ? "5vw" : "5%"}
        py={!sm ? "10vh" : "10%"}
      >
        <Box style={{ position: "absolute", right: "15px", top: "15px" }}>
          <Close
            style={{ color: "black", cursor: "pointer" }}
            onClick={resetPopup}
          />
        </Box>
        <div className={classes.background}>
          <div className={classes.container}>
            <div className="d-flex justify-content-end">
              <Close
                style={{
                  position: "absolute",
                  top: 5,
                  right: 5,
                  color: "#000000",
                  fontWeight: 800,
                  cursor: "pointer",
                }}
                onClick={resetPopup}
              />
            </div>
            {txCase === 5 && (
              <div
                className="row flex-row align-items-center justify-content-center"
                align="center"
                style={{ height: "80%", width: "100%" }}
              >
                <div className="col-md-7">
                  <div>
                    <Typography variant="h2" className={classes.heading}>
                      Whisk Whisk Whisk !
                    </Typography>
                    <Typography variant="h5" className={classes.para}>
                      WAITING FOR YOUR TRANSACTION TO CONFIRM
                    </Typography>
                  </div>
                </div>
                <div className="col-md-5">
                  <div>
                    <img src="images/cake_mixer.png" width="70%" />
                  </div>
                </div>
              </div>
            )}
            {txCase === 6 && (
              <div
                className="row flex-row align-items-center justify-content-center"
                align="center"
                style={{ height: "80%", width: "100%" }}
              >
                <div className="col-md-5">
                  <div>
                    <img src="images/farm_failed.png" width="70%" />
                  </div>
                </div>
                <div className="col-md-7">
                  <div>
                    <Typography variant="h2" className={classes.heading}>
                      Oops ! The Shovel Broke !
                    </Typography>
                    <Typography variant="h5" className={classes.para}>
                      YOUR TRANSACTION DID NOT GO THROUGH. <br />
                      PLEASE TRY AGAIN.
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="center"
                      style={{ marginTop: 10 }}
                    >
                      <Link
                        to="/farm"
                        style={{ textDecoration: "none", marginTop: 10 }}
                      >
                        <Button
                          className={classes.reloadButton}
                          onClick={resetPopup}
                        >
                          Back to Farm
                        </Button>
                      </Link>
                    </Box>
                  </div>
                </div>
              </div>
            )}
            {txCase === 7 && (
              <div
                className="row flex-row align-items-center justify-content-between"
                align="center"
                style={{ height: "80%", width: "100%" }}
              >
                <div className="col-md-5">
                  <div>
                    <img
                      src="images/claim_farm.png"
                      className={classes.svgImage}
                    />
                  </div>
                </div>
                <div className="col-md-7">
                  <div>
                    <Typography variant="h2" className={classes.heading}>
                      Farm Ready!
                    </Typography>
                    <Typography variant="h5" className={classes.para}>
                      YOUR $ORARE HAS BEEN SUCCESSFULLY STAKED. <br />
                      KEEP AN EYE ON YOUR HARVEST, AS INGREDIENTS POP UP EVERY
                      FEW HOURS !
                    </Typography>

                    <Box
                      display="flex"
                      justifyContent="center"
                      style={{ marginTop: 10 }}
                    >
                      <Link to="/farm" style={{ textDecoration: "none" }}>
                        <Button
                          className={classes.reloadButton}
                          onClick={resetPopup}
                        >
                          Back to Farm
                        </Button>
                      </Link>
                    </Box>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </Box>
    </Dialog>
  );
};

export default TxPopup;

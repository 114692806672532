import { gql } from "@apollo/client";

export const GetIngredientsData = gql`
  query IngredientEntities {
    ingredientEntities(
      first: 1000
      orderBy: ingredientCount
      where: {
        currentOwner: true
        address: "0xA52264dbcbaC140E26072D763D5Ee887Ae869F13"
        category_not: null
      }
    ) {
      ingredientCount
      ingredientData {
        name
        id
        tokenId
        category
      }
    }
  }
`;

export const GetIngredientsDataByAddress = gql`
  query IngredientEntities {
    ingredientEntities(
      first: 1000
      orderBy: ingredientCount
      where: {
        currentOwner: true
        address: "0xe42cd0b8D29db446E6a4dA6C7F6D1A683A7B9e45"
        ingredientCount_not: 0
      }
    ) {
      ingredientCount
      ingredientData {
        name
        id
        tokenId
        category
      }
    }
  }
`;

export const GetDishLockData = gql`
  query DishDatas {
    dishDatas {
      id
      name
      locked
    }
  }
`;

export const GetAdminStatsData = gql`
  query GetAdminStatsData {
    statsEntity(id: "1") {
      orareSpent
      ingrClaimed
      ingrBought
      dishesBought
      dishMintedCount
    }
  }
`;

import { useState } from "react";
import { Box, Button, Input, Modal } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 16,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: 300,
};
const useStyles = makeStyles((theme) => ({
  input: {
    margin: "4px 0",
    width: 400,
  },
}));
const SetNfts = ({ open, setOpen, handleOpen }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [name, setName] = useState("");

  const handleClose = () => setOpen(false);

  const onSubmit = (e) => {
    e.preventDefault();
    console.log("name", name);
    handleClose();
    setName("");
  };
  return (
    <Modal
      keepMounted
      open={open}
      onClose={() => handleClose()}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      disableEnforceFocus
    >
      <Box sx={style}>
        <h1>Update Quantity</h1>
        <br />
        <form onSubmit={onSubmit} className="d-flex flex-column align-end">
          <Input
            placeholder="Enter quantity"
            value={name || ""}
            onChange={(e) => setName(e.target.value)}
            className={classes.input}
          />
          <br />
          <Button
            type="submit"
            sx={{
              background: `rgba(224, 7, 125, 0.7)`,
              color: "#000000",
              width: "max-content",
              height: 40,
              margin: "0 auto",
              textTransform: "none",
              borderRadius: "30px",
              fontSize: 15,
              padding: "5px 20px 5px 20px",
              "&:hover": {
                background: "rgba(224, 7, 125, 0.7)",
              },
              cursor: "pointer",
              [theme.breakpoints.down("md")]: {
                width: "fit-content",
                fontSize: 12,
              },
            }}
          >
            Update
          </Button>
        </form>
      </Box>
    </Modal>
  );
};
export default SetNfts;

import "./App.css";
import React, { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import Home from "./pages/Home";
import "./web3";
import Navbar from "./common/Navbar";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Admins from "./pages/Contracts/Admins";
import Leftbar from "./common/Leftbar";
import Contracts from "./pages/Contracts";
import PartyContract from "./pages/Contracts/components/PartyContract";
import ERC1155Contract from "./pages/Contracts/components/ERC1155Detail";
import DefiFarming from "./pages/Contracts/components/DefiFarming";
import VaultContract from "./pages/Contracts/components/VaultContract";
import { useMetamask } from "./useMetamask";
import ReleaseNew from "./pages/Platform/ReleaseNew";
import { useMediaQuery, useTheme } from "@mui/material";
import AppDashboard from "./pages/AppDashboard";
import Login from "./common/Login";
import UserDetails from "./pages/Users/UserDetails";
import VaultIngredients from "./pages/Vault/VaultIngredients";
import Users from "./pages/Users";
import WithdrawRequests from "./pages/WithdrawRequests";
import Coupons from "./pages/Coupons";
import CouponUsersDetails from "./pages/Coupons/CouponUsersDetails";

function App() {
  // const { logout, loginWithMetamask, accountSC } = useMetamask();
  const [page, setPage] = useState("balances");
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();

  const tele = window.Telegram.WebApp;

  useEffect(() => {
    tele.ready();
    console.log(tele);
  });

  let accountSC = "0x9D7117a07fca9F22911d379A9fd5118A5FA4F448";

  const admins = [
    "0xd85a647b27b2cc988caed8556c373d1b7e9567c3",
    "0xdd270eb989df9a239cdd9af235d9a295bd0d468a",
    "0xDD416AAA9B3E3aBDC015b9085b354B93140b8210",
    "0x9D7117a07fca9F22911d379A9fd5118A5FA4F448",
    "0xCC863109a4838637781b78e08F06743cE0148EEF",
    "0x2F8758e262A2aBc5bE41c40bAD8d3695fc801295",
    "0xBD5a0c87c16C7b0Afb24c7a0542c71faF3861284",
    "0x4A6a837E46098b4c05058284F8d111E626890809",
    "0xe9e22158a743aAE5325184a94D4382d7B20102Bb",
  ];

  useEffect(() => {
    if (accountSC) {
      let adminExist = admins.find(
        (ele) => ele.toLowerCase() === accountSC.toLowerCase()
      );
      if (adminExist) {
        navigate("/");
      } else {
        navigate("/login");
      }
    }
  }, [accountSC]);

  // useEffect(() => {
  //   console.log(accountSC);
  //   if (!accountSC) {
  //     navigate("/login");
  //   }
  // }, [accountSC]);

  return (
    <ThemeProvider theme={theme}>
      <div className="row w-100">
        {window.location.pathname != "/login" && !md && (
          <div
            className="col-md-2 sticky-top"
            style={{
              backgroundColor: "#0C0D11",
              height: "100vh",
            }}
          >
            {accountSC && <Leftbar />}
          </div>
        )}
        <div className="col-md-10" style={{ backgroundColor: "#000000" }}>
          {window.location.pathname !== "/login" ? (
            accountSC && (
              <div className="">
                <Navbar page={page} setPage={setPage} />
                <Routes>
                  <Route exact path="/" element={<Home />} />
                  <Route exact path="/login" element={<Login />} />
                  <Route exact path="/admins" element={<Admins />} />
                  <Route exact path="/contracts" element={<Contracts />} />

                  <Route exact path="/dishes" element={<ReleaseNew />} />
                  <Route exact path="/app" element={<AppDashboard />} />
                  <Route exact path="/users" element={<Users />} />

                  <Route exact path="/vault" element={<VaultIngredients />} />

                  <Route
                    exact
                    path="/contract/party"
                    element={<PartyContract />}
                  />
                  <Route
                    exact
                    path="/contract/erc1155"
                    element={<ERC1155Contract />}
                  />
                  <Route
                    exact
                    path="/contract/farming"
                    element={<DefiFarming />}
                  />
                  <Route
                    exact
                    path="/contract/vault"
                    element={<VaultContract />}
                  />
                  <Route exact path="/users/:id" element={<UserDetails />} />
                  <Route
                    exact
                    path="/withdraw-requests"
                    element={<WithdrawRequests />}
                  />
                  <Route exact path="/coupons" element={<Coupons />} />
                  <Route
                    exact
                    path="/coupons/:id"
                    element={<CouponUsersDetails />}
                  />
                </Routes>
              </div>
            )
          ) : (
            <Routes>
              <Route exact path="/login" element={<Login />} />
            </Routes>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;

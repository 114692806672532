import Web3 from "web3";
import constants from "./constants";

let marketConstant;
if (constants.net === 0) {
  marketConstant = {
    contractAddress: constants.marketplace_contract,
    abi: [
      {
        inputs: [],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address[]",
            name: "accounts",
            type: "address[]",
          },
        ],
        name: "AdminAdded",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "previousAdmin",
            type: "address",
          },
          {
            indexed: false,
            internalType: "address",
            name: "newAdmin",
            type: "address",
          },
        ],
        name: "AdminChanged",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address[]",
            name: "accounts",
            type: "address[]",
          },
        ],
        name: "AdminRemoved",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "seller",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "tokenId",
            type: "uint256[]",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "orderId",
            type: "uint256[]",
          },
        ],
        name: "BatchOrderCancelled",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "seller",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "tokenId",
            type: "uint256[]",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "orderId_",
            type: "uint256[]",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "pice",
            type: "uint256[]",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "quantity",
            type: "uint256[]",
          },
        ],
        name: "BatchOrderCreated",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "buyer",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "tokenIds",
            type: "uint256[]",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "orderIds",
            type: "uint256[]",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "quantity",
            type: "uint256[]",
          },
        ],
        name: "BatchOrderExecuted",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "beacon",
            type: "address",
          },
        ],
        name: "BeaconUpgraded",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "ERC1155TokensRecovered",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "ERC20TokensRecovered",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "seller",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "orderId",
            type: "uint256",
          },
        ],
        name: "OrderCancelled",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "seller",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "orderId_",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "pice",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "quantity",
            type: "uint256",
          },
        ],
        name: "OrderCreated",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "buyer",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "orderIds",
            type: "uint256[]",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "quantity",
            type: "uint256[]",
          },
        ],
        name: "OrderExecuted",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "previousOwner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "newOwner",
            type: "address",
          },
        ],
        name: "OwnershipTransferred",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "account",
            type: "address",
          },
        ],
        name: "Paused",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "account",
            type: "address",
          },
        ],
        name: "Unpaused",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "implementation",
            type: "address",
          },
        ],
        name: "Upgraded",
        type: "event",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "nftId_",
            type: "uint256",
          },
          {
            internalType: "uint256[]",
            name: "orderIds_",
            type: "uint256[]",
          },
          {
            internalType: "uint256[]",
            name: "quantities_",
            type: "uint256[]",
          },
        ],
        name: "batchBuyFromOrderId",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256[]",
            name: "tokenIds_",
            type: "uint256[]",
          },
          {
            internalType: "uint256[]",
            name: "orderIds_",
            type: "uint256[]",
          },
          {
            internalType: "uint256[]",
            name: "quantities_",
            type: "uint256[]",
          },
        ],
        name: "batchBuyFromOrderTokenIds",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256[]",
            name: "nftIds_",
            type: "uint256[]",
          },
          {
            internalType: "uint256[]",
            name: "orderIds_",
            type: "uint256[]",
          },
        ],
        name: "batchCancelOrder",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256[]",
            name: "nftIds_",
            type: "uint256[]",
          },
          {
            internalType: "uint256[]",
            name: "quantities_",
            type: "uint256[]",
          },
          {
            internalType: "uint256[]",
            name: "prices_",
            type: "uint256[]",
          },
        ],
        name: "batchCreateOrder",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "nftId_",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "orderId_",
            type: "uint256",
          },
        ],
        name: "cancelOrder",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "nftId_",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "quantity_",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "price_",
            type: "uint256",
          },
        ],
        name: "createOrder",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "feeManager",
        outputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "feePercent",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "nftId_",
            type: "uint256",
          },
        ],
        name: "getAllOrder",
        outputs: [
          {
            components: [
              {
                internalType: "address",
                name: "seller",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "price",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "quantity",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "filled",
                type: "uint256",
              },
              {
                internalType: "enum OrderStatus",
                name: "status",
                type: "uint8",
              },
            ],
            internalType: "struct Order[]",
            name: "",
            type: "tuple[]",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "nftId_",
            type: "uint256",
          },
        ],
        name: "getCompletedOrder",
        outputs: [
          {
            internalType: "uint256[]",
            name: "",
            type: "uint256[]",
          },
          {
            components: [
              {
                internalType: "address",
                name: "seller",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "price",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "quantity",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "filled",
                type: "uint256",
              },
              {
                internalType: "enum OrderStatus",
                name: "status",
                type: "uint8",
              },
            ],
            internalType: "struct Order[]",
            name: "",
            type: "tuple[]",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "nftId_",
            type: "uint256",
          },
        ],
        name: "getOrderBookSize",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "nftId_",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "orderId_",
            type: "uint256",
          },
        ],
        name: "getOrderById",
        outputs: [
          {
            components: [
              {
                internalType: "address",
                name: "seller",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "price",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "quantity",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "filled",
                type: "uint256",
              },
              {
                internalType: "enum OrderStatus",
                name: "status",
                type: "uint8",
              },
            ],
            internalType: "struct Order",
            name: "",
            type: "tuple",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "nftId_",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "cursor_",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "limit_",
            type: "uint256",
          },
        ],
        name: "getOrderByRange",
        outputs: [
          {
            components: [
              {
                internalType: "address",
                name: "seller",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "price",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "quantity",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "filled",
                type: "uint256",
              },
              {
                internalType: "enum OrderStatus",
                name: "status",
                type: "uint8",
              },
            ],
            internalType: "struct Order[]",
            name: "",
            type: "tuple[]",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256[]",
            name: "nftIds_",
            type: "uint256[]",
          },
          {
            internalType: "uint256[]",
            name: "orderIds_",
            type: "uint256[]",
          },
        ],
        name: "getOrders",
        outputs: [
          {
            components: [
              {
                internalType: "address",
                name: "seller",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "price",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "quantity",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "filled",
                type: "uint256",
              },
              {
                internalType: "enum OrderStatus",
                name: "status",
                type: "uint8",
              },
            ],
            internalType: "struct Order[]",
            name: "",
            type: "tuple[]",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "nftId_",
            type: "uint256",
          },
        ],
        name: "getPendingOrder",
        outputs: [
          {
            internalType: "uint256[]",
            name: "",
            type: "uint256[]",
          },
          {
            components: [
              {
                internalType: "address",
                name: "seller",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "price",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "quantity",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "filled",
                type: "uint256",
              },
              {
                internalType: "enum OrderStatus",
                name: "status",
                type: "uint8",
              },
            ],
            internalType: "struct Order[]",
            name: "",
            type: "tuple[]",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "owner_",
            type: "address",
          },
          {
            internalType: "address",
            name: "oRareNft_",
            type: "address",
          },
          {
            internalType: "address",
            name: "oRareToken_",
            type: "address",
          },
          {
            internalType: "address",
            name: "feeManager_",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "feePercent_",
            type: "uint256",
          },
        ],
        name: "initialize",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "oRareNft",
        outputs: [
          {
            internalType: "contract IERC1155",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "oRareToken",
        outputs: [
          {
            internalType: "contract IERC20",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
          {
            internalType: "address",
            name: "",
            type: "address",
          },
          {
            internalType: "uint256[]",
            name: "",
            type: "uint256[]",
          },
          {
            internalType: "uint256[]",
            name: "",
            type: "uint256[]",
          },
          {
            internalType: "bytes",
            name: "",
            type: "bytes",
          },
        ],
        name: "onERC1155BatchReceived",
        outputs: [
          {
            internalType: "bytes4",
            name: "",
            type: "bytes4",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
          {
            internalType: "address",
            name: "",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "",
            type: "bytes",
          },
        ],
        name: "onERC1155Received",
        outputs: [
          {
            internalType: "bytes4",
            name: "",
            type: "bytes4",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "owner",
        outputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "pauseContract",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "paused",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "to_",
            type: "address",
          },
          {
            internalType: "address",
            name: "token_",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "tokenId_",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "amount_",
            type: "uint256",
          },
        ],
        name: "recoverERC1155",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "to_",
            type: "address",
          },
          {
            internalType: "address",
            name: "token_",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount_",
            type: "uint256",
          },
        ],
        name: "recoverERC20",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "bytes4",
            name: "interfaceId",
            type: "bytes4",
          },
        ],
        name: "supportsInterface",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "newOwner_",
            type: "address",
          },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "unpauseContract",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "feeManager_",
            type: "address",
          },
        ],
        name: "updateFeeManager",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "feePercent_",
            type: "uint256",
          },
        ],
        name: "updateFeePercent",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "newImplementation",
            type: "address",
          },
        ],
        name: "upgradeTo",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "newImplementation",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "data",
            type: "bytes",
          },
        ],
        name: "upgradeToAndCall",
        outputs: [],
        stateMutability: "payable",
        type: "function",
      },
    ],
  };
} else {
  marketConstant = {
    contractAddress: constants.marketplace_contract,
    abi: [
      {
        inputs: [],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address[]",
            name: "accounts",
            type: "address[]",
          },
        ],
        name: "AdminAdded",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "previousAdmin",
            type: "address",
          },
          {
            indexed: false,
            internalType: "address",
            name: "newAdmin",
            type: "address",
          },
        ],
        name: "AdminChanged",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address[]",
            name: "accounts",
            type: "address[]",
          },
        ],
        name: "AdminRemoved",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "seller",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "tokenId",
            type: "uint256[]",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "orderId",
            type: "uint256[]",
          },
        ],
        name: "BatchOrderCancelled",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "seller",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "tokenId",
            type: "uint256[]",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "orderId_",
            type: "uint256[]",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "pice",
            type: "uint256[]",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "quantity",
            type: "uint256[]",
          },
        ],
        name: "BatchOrderCreated",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "buyer",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "tokenIds",
            type: "uint256[]",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "orderIds",
            type: "uint256[]",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "quantity",
            type: "uint256[]",
          },
        ],
        name: "BatchOrderExecuted",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "beacon",
            type: "address",
          },
        ],
        name: "BeaconUpgraded",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "ERC1155TokensRecovered",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "token",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "ERC20TokensRecovered",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "seller",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "orderId",
            type: "uint256",
          },
        ],
        name: "OrderCancelled",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "seller",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "orderId_",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "pice",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "quantity",
            type: "uint256",
          },
        ],
        name: "OrderCreated",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "buyer",
            type: "address",
          },
          {
            indexed: true,
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "orderIds",
            type: "uint256[]",
          },
          {
            indexed: false,
            internalType: "uint256[]",
            name: "quantity",
            type: "uint256[]",
          },
        ],
        name: "OrderExecuted",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "previousOwner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "newOwner",
            type: "address",
          },
        ],
        name: "OwnershipTransferred",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "account",
            type: "address",
          },
        ],
        name: "Paused",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: false,
            internalType: "address",
            name: "account",
            type: "address",
          },
        ],
        name: "Unpaused",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "implementation",
            type: "address",
          },
        ],
        name: "Upgraded",
        type: "event",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "nftId_",
            type: "uint256",
          },
          {
            internalType: "uint256[]",
            name: "orderIds_",
            type: "uint256[]",
          },
          {
            internalType: "uint256[]",
            name: "quantities_",
            type: "uint256[]",
          },
        ],
        name: "batchBuyFromOrderId",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256[]",
            name: "tokenIds_",
            type: "uint256[]",
          },
          {
            internalType: "uint256[]",
            name: "orderIds_",
            type: "uint256[]",
          },
          {
            internalType: "uint256[]",
            name: "quantities_",
            type: "uint256[]",
          },
        ],
        name: "batchBuyFromOrderTokenIds",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256[]",
            name: "nftIds_",
            type: "uint256[]",
          },
          {
            internalType: "uint256[]",
            name: "orderIds_",
            type: "uint256[]",
          },
        ],
        name: "batchCancelOrder",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256[]",
            name: "nftIds_",
            type: "uint256[]",
          },
          {
            internalType: "uint256[]",
            name: "quantities_",
            type: "uint256[]",
          },
          {
            internalType: "uint256[]",
            name: "prices_",
            type: "uint256[]",
          },
        ],
        name: "batchCreateOrder",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "nftId_",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "orderId_",
            type: "uint256",
          },
        ],
        name: "cancelOrder",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "nftId_",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "quantity_",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "price_",
            type: "uint256",
          },
        ],
        name: "createOrder",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "feeManager",
        outputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "feePercent",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "nftId_",
            type: "uint256",
          },
        ],
        name: "getAllOrder",
        outputs: [
          {
            components: [
              {
                internalType: "address",
                name: "seller",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "price",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "quantity",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "filled",
                type: "uint256",
              },
              {
                internalType: "enum OrderStatus",
                name: "status",
                type: "uint8",
              },
            ],
            internalType: "struct Order[]",
            name: "",
            type: "tuple[]",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "nftId_",
            type: "uint256",
          },
        ],
        name: "getCompletedOrder",
        outputs: [
          {
            internalType: "uint256[]",
            name: "",
            type: "uint256[]",
          },
          {
            components: [
              {
                internalType: "address",
                name: "seller",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "price",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "quantity",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "filled",
                type: "uint256",
              },
              {
                internalType: "enum OrderStatus",
                name: "status",
                type: "uint8",
              },
            ],
            internalType: "struct Order[]",
            name: "",
            type: "tuple[]",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "nftId_",
            type: "uint256",
          },
        ],
        name: "getOrderBookSize",
        outputs: [
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "nftId_",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "orderId_",
            type: "uint256",
          },
        ],
        name: "getOrderById",
        outputs: [
          {
            components: [
              {
                internalType: "address",
                name: "seller",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "price",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "quantity",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "filled",
                type: "uint256",
              },
              {
                internalType: "enum OrderStatus",
                name: "status",
                type: "uint8",
              },
            ],
            internalType: "struct Order",
            name: "",
            type: "tuple",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "nftId_",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "cursor_",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "limit_",
            type: "uint256",
          },
        ],
        name: "getOrderByRange",
        outputs: [
          {
            components: [
              {
                internalType: "address",
                name: "seller",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "price",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "quantity",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "filled",
                type: "uint256",
              },
              {
                internalType: "enum OrderStatus",
                name: "status",
                type: "uint8",
              },
            ],
            internalType: "struct Order[]",
            name: "",
            type: "tuple[]",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256[]",
            name: "nftIds_",
            type: "uint256[]",
          },
          {
            internalType: "uint256[]",
            name: "orderIds_",
            type: "uint256[]",
          },
        ],
        name: "getOrders",
        outputs: [
          {
            components: [
              {
                internalType: "address",
                name: "seller",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "price",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "quantity",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "filled",
                type: "uint256",
              },
              {
                internalType: "enum OrderStatus",
                name: "status",
                type: "uint8",
              },
            ],
            internalType: "struct Order[]",
            name: "",
            type: "tuple[]",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "nftId_",
            type: "uint256",
          },
        ],
        name: "getPendingOrder",
        outputs: [
          {
            internalType: "uint256[]",
            name: "",
            type: "uint256[]",
          },
          {
            components: [
              {
                internalType: "address",
                name: "seller",
                type: "address",
              },
              {
                internalType: "uint256",
                name: "price",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "quantity",
                type: "uint256",
              },
              {
                internalType: "uint256",
                name: "filled",
                type: "uint256",
              },
              {
                internalType: "enum OrderStatus",
                name: "status",
                type: "uint8",
              },
            ],
            internalType: "struct Order[]",
            name: "",
            type: "tuple[]",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "owner_",
            type: "address",
          },
          {
            internalType: "address",
            name: "oRareNft_",
            type: "address",
          },
          {
            internalType: "address",
            name: "oRareToken_",
            type: "address",
          },
          {
            internalType: "address",
            name: "feeManager_",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "feePercent_",
            type: "uint256",
          },
        ],
        name: "initialize",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "oRareNft",
        outputs: [
          {
            internalType: "contract IERC1155",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "oRareToken",
        outputs: [
          {
            internalType: "contract IERC20",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
          {
            internalType: "address",
            name: "",
            type: "address",
          },
          {
            internalType: "uint256[]",
            name: "",
            type: "uint256[]",
          },
          {
            internalType: "uint256[]",
            name: "",
            type: "uint256[]",
          },
          {
            internalType: "bytes",
            name: "",
            type: "bytes",
          },
        ],
        name: "onERC1155BatchReceived",
        outputs: [
          {
            internalType: "bytes4",
            name: "",
            type: "bytes4",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
          {
            internalType: "address",
            name: "",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "",
            type: "uint256",
          },
          {
            internalType: "bytes",
            name: "",
            type: "bytes",
          },
        ],
        name: "onERC1155Received",
        outputs: [
          {
            internalType: "bytes4",
            name: "",
            type: "bytes4",
          },
        ],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "owner",
        outputs: [
          {
            internalType: "address",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [],
        name: "pauseContract",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "paused",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "to_",
            type: "address",
          },
          {
            internalType: "address",
            name: "token_",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "tokenId_",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "amount_",
            type: "uint256",
          },
        ],
        name: "recoverERC1155",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "to_",
            type: "address",
          },
          {
            internalType: "address",
            name: "token_",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount_",
            type: "uint256",
          },
        ],
        name: "recoverERC20",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "bytes4",
            name: "interfaceId",
            type: "bytes4",
          },
        ],
        name: "supportsInterface",
        outputs: [
          {
            internalType: "bool",
            name: "",
            type: "bool",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "newOwner_",
            type: "address",
          },
        ],
        name: "transferOwnership",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [],
        name: "unpauseContract",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "feeManager_",
            type: "address",
          },
        ],
        name: "updateFeeManager",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "uint256",
            name: "feePercent_",
            type: "uint256",
          },
        ],
        name: "updateFeePercent",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "newImplementation",
            type: "address",
          },
        ],
        name: "upgradeTo",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address",
            name: "newImplementation",
            type: "address",
          },
          {
            internalType: "bytes",
            name: "data",
            type: "bytes",
          },
        ],
        name: "upgradeToAndCall",
        outputs: [],
        stateMutability: "payable",
        type: "function",
      },
    ],
  };
}

var web3 = new Web3(typeof window !== "undefined" && window.ethereum);

var marketContract = new web3.eth.Contract(
  marketConstant.abi,
  marketConstant.contractAddress
);

export default marketContract;

import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";
import DeleteBrandPopup from "./DeleteBrandPopup";
import CreateCouponPopup from "./CreateCouponPopup";

function AppAccordion({ brandName, brandImage, title, address, coupons }) {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const [expanded, setExpanded] = React.useState(false);
  const [openDeletePopup, setOpenDeletePopup] = React.useState(false);
  const [createCouponPopup, setCreateCouponPopup] = React.useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const onRemoveCoupon = async (couponId) => {
    var data = JSON.stringify({
      couponId: couponId,
      minterAddress: address,
    });

    var config = {
      method: "post",
      url: "https://superapp-backend.onerare.io/deleteCoupon",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await axios(config);
      console.log(response.data.result);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <DeleteBrandPopup
        open={openDeletePopup}
        setOpen={setOpenDeletePopup}
        minterAddress={address}
      />
      {/* <CreateCouponPopup
        open={createCouponPopup}
        setOpen={setCreateCouponPopup}
        minterAddress={address}
      /> */}
      <Accordion
        expanded={expanded}
        onChange={handleChange}
        style={{
          backgroundColor: "#212121",
          color: "#fff",
          borderRadius: "16px",
          marginBottom: "15px",
          padding: md ? "0px" : "15px",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Box
            sx={{
              width: md ? "25%" : "40%",
              flexShrink: 0,
              display: "flex",
              justifyContent: !md ? "flex-start" : "center",
              alignItems: md ? "flex-start" : "center",
              flexDirection: md && "column",
              gap: "10px",
            }}
          >
            <img
              src={brandImage}
              style={{
                width: "100%",
                height: "100%",
                maxHeight: md ? 50 : 80,
                maxWidth: md ? 50 : 80,
                objectFit: "contain",
              }}
            />
            <Typography
              sx={{
                color: "#00ffff",
                fontSize: md ? 14 : 20,
                lineHeight: "70%",
                fontWeight: "bold",
              }}
            >
              {brandName}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: md ? "center" : "flex-start",
              gap: "10px",
              width: "100%",
            }}
          >
            <Typography style={{ color: "#A3A3A3", fontSize: md && 12 }}>
              Active Coupons:
              <span
                style={{ color: "#fff", fontWeight: "bold", paddingLeft: 5 }}
              >
                {coupons?.length}
              </span>
            </Typography>
            <Typography style={{ color: "#A3A3A3", fontSize: md && 12 }}>
              Expired Coupons:{" "}
              <span
                style={{ color: "#fff", fontWeight: "bold", paddingLeft: 5 }}
              >
                -
              </span>
            </Typography>
          </Box>
          <Box
            style={{
              width: md ? "25%" : "40%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <Button
              style={{
                color: "#D82B2B",
                border: "1px solid #D82B2B",
                padding: md ? "7px 15px" : "10px 25px",
                borderRadius: "8px",
                fontSize: md && 12,
                lineHeight: md && "95%",
              }}
              onClick={() => setOpenDeletePopup(true)}
            >
              Remove Brand
            </Button>
            {/* <Button
              style={{
                color: "#00ffff",
                border: "1px solid #00ffff",
                padding: md?"7px 15px":"10px 25px",
                borderRadius: "8px",
                fontSize: md && 12,
                lineHeight: "95%"
                marginBottom: "10px",
              }}
              onClick={() => setCreateCouponPopup(true)}
            >
              Create Coupon
            </Button> */}
          </Box>
        </AccordionSummary>
        {coupons.length > 0 && (
          <AccordionDetails>
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <table style={{ width: "100%", maxWidth: "500px" }}>
                <thead>
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                        color: "#A3A3A3",
                        paddingBottom: "10px",
                      }}
                    >
                      All Coupons
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        color: "#A3A3A3",
                        paddingBottom: "10px",
                      }}
                    >
                      Quantity
                    </td>
                    <td
                      style={{
                        textAlign: "center",
                        color: "#A3A3A3",
                        paddingBottom: "10px",
                      }}
                    >
                      Redeemed
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {coupons.map((coupon, i) => (
                    <tr key={coupon.couponId}>
                      <td style={{ textAlign: "center" }}>{coupon.name}</td>
                      <td style={{ textAlign: "center" }}>{coupon.amount}</td>
                      <td style={{ textAlign: "center" }}>{coupon.redeemed}</td>
                      {/* <td
                        style={{ textAlign: "center", cursor: "pointer" }}
                        onClick={() => onRemoveCoupon(coupon.couponId)}
                      >
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 112 112"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="31.2308"
                            y="35.8976"
                            width="49.5385"
                            height="58.8718"
                            rx="5.38462"
                            stroke="#D82B2B"
                            strokeWidth="6.46154"
                            strokeLinejoin="round"
                          />
                          <line
                            x1="48.8189"
                            y1="58.1538"
                            x2="48.8189"
                            y2="81.8462"
                            stroke="#D82B2B"
                            strokeWidth="4.30769"
                            strokeLinecap="round"
                          />
                          <line
                            x1="85.4343"
                            y1="26.5639"
                            x2="26.5625"
                            y2="26.5639"
                            stroke="#D82B2B"
                            strokeWidth="6.46154"
                            strokeLinecap="round"
                          />
                          <line
                            x1="62.8189"
                            y1="58.1538"
                            x2="62.8189"
                            y2="81.8462"
                            stroke="#D82B2B"
                            strokeWidth="4.30769"
                            strokeLinecap="round"
                          />
                          <path
                            d="M65.3317 23.3333C65.3317 22.1077 65.0903 20.894 64.6212 19.7616C64.1522 18.6292 63.4647 17.6003 62.598 16.7337C61.7314 15.867 60.7025 15.1795 59.5701 14.7105C58.4377 14.2414 57.224 14 55.9984 14C54.7727 14 53.559 14.2414 52.4267 14.7105C51.2943 15.1795 50.2654 15.867 49.3987 16.7337C48.532 17.6004 47.8445 18.6292 47.3755 19.7616C46.9065 20.894 46.665 22.1077 46.665 23.3333"
                            stroke="#D82B2B"
                            strokeWidth="4.30769"
                          />
                        </svg>
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </Box>
          </AccordionDetails>
        )}
      </Accordion>
    </>
  );
}

export default AppAccordion;

import * as React from "react";

import { Card, Button } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { Link } from "react-router-dom";
import { CreditCard, Group } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 10,
  },
  title: {
    fontWeight: 700,
    fontSize: 18,
    textAlign: "center",
    color: "#0000000",
  },
  value: {
    fontWeight: 400,
    textAlign: "center",
    fontSize: 14,
    color: "#757575",
  },
  cover: {
    height: "200px",
    width: "100%",
    backgroundImage: `url('https://pbs.twimg.com/media/FZ-GH_waIAEgTRM?format=jpg&name=large')`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));

const ContractCard = ({ name }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div>
      <Card
        sx={{
          width: 300,
          height: 300,
          marginRight: "20px",
          marginTop: "30px",
          borderRadius: "10%",
          backgroundColor: "#212121",
          filter: "drop-shadow(0 0 0.5rem #e5e5e5)",
          [theme.breakpoints.down("sm")]: {
            paddingLeft: 0,
            paddingRight: 0,
            width: "100%",
            height: "100%",
          },
        }}
      >
        <div className={classes.cover} />
        <div className={classes.container}>
          <div className={classes.title}>{name}</div>
          <div className={classes.value}>
            Click and update the contract features
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ContractCard;

import { makeStyles } from "@mui/styles";
import { VerifiedUser, Wallet } from "@mui/icons-material";
import { useEffect, useState } from "react";

import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  getOrareBalanceByAddress,
  getUserMaticBalance,
} from "../../actions/smartActions";
import { getUserFoodTruckWarsBalance } from "../../actions/serverActions";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: "#0C0D11",
    marginTop: 20,
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    width: "100%",
    minHeight: 250,
    border: "1px solid #1b1d24",
    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.03)",
    borderRadius: 14,
    "&:hover": {
      boxShadow: "0px 24px 33px -9px #0000005C",
    },

    [theme.breakpoints.down("md")]: {
      height: "100%",
      width: "100%",
    },
  },
}));

const addresses = [
  {
    address: "0x236475eec9f3c8597fbd0c76016b18f494160399",
  },
  {
    address: "0xab7023DAD3531468BcA6E1cC212A23C36B871BC8",
  },
  {
    address: "0x700B229f8863149fa3330075A0723703ccBD46DA",
  },
  {
    address: "0x9F88a10b14f60a9c4d3589C54853468f1C9c7485",
  },
  {
    address: "0x70167F52c1BB16714c1cbb9C839655b70dec2423",
  },
  {
    address: "0x6585633264024f330B03430D2a3fF708eC92E5f2",
  },
  {
    address: "0x6e3A43D40858A1Bc327d49dc5B6B1CA5A68B42a7",
  },
];

const AnalyticsWalletBalances = () => {
  const classes = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const [maticBalances, setMaticBalances] = useState([]);

  useEffect(() => {
    (async () => {
      let tempArray = [];
      addresses.map(async (user, i) => {
        const maticBalance = await getUserMaticBalance(user.address);
        const orareBalance = await getUserFoodTruckWarsBalance(user.address);
        tempArray.push({
          address: user.address,
          balance: maticBalance,
          orareBalance,
        });
        tempArray.sort((a, b) => b.balance.localeCompare(a.balance));
        setMaticBalances([...tempArray]);
      });
    })();
  }, []);

  return (
    <div className="mt-5 mb-3">
      <Typography fontWeight={600} fontSize={18} color={"#f9f9f9"}>
        Wallet Balances
      </Typography>

      <Box mt={3}>
        {maticBalances.map((user, index) => (
          <Box
            key={index}
            style={{
              border: "1px solid #0C0D12",
              backgroundColor: "#111214",
              borderRadius: 14,
              padding: 10,
              paddingLeft: md ? "5%" : 20,
              paddingRight: md ? "5%" : 20,
              width: "auto",
            }}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Box
              width={md ? "46%" : "30%"}
              display={"flex"}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              <IconButton
                color="success"
                style={{
                  backgroundColor: "#36393E",
                  height: md ? 15 : 30,
                  width: md ? 15 : 30,
                }}
              >
                <Wallet style={{ fontSize: 16 }} />
              </IconButton>
              <Box ml={2}>
                <Typography
                  fontWeight={300}
                  fontSize={md ? 9 : 12}
                  color={"#e5e5e5"}
                >
                  {index === 0 && "COOK & UPGRADE Admin"}
                  {index != 0 && `Admin${index}`}
                </Typography>
                <Typography
                  fontWeight={600}
                  fontSize={md ? 9 : 12}
                  color={"#fff"}
                >
                  {md
                    ? `${[...user.address].splice(
                        0,
                        6
                      )}......${user.address.slice(-6)}`
                    : user.address}
                </Typography>
              </Box>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box ml={2}>
                <Typography
                  fontWeight={300}
                  fontSize={md ? 9 : 12}
                  color={"#e5e5e5"}
                >
                  $ORARE
                </Typography>
                <Typography
                  fontWeight={600}
                  fontSize={md ? 11 : 14}
                  color={"#fff"}
                >
                  {user.orareBalance}
                </Typography>
              </Box>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Box ml={2}>
                <Typography
                  fontWeight={300}
                  fontSize={md ? 9 : 12}
                  color={"#e5e5e5"}
                >
                  Matic Balance
                </Typography>
                <Typography
                  fontWeight={600}
                  fontSize={md ? 11 : 14}
                  color={"#fff"}
                >
                  {user.balance}
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default AnalyticsWalletBalances;

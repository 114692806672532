import { useState, useEffect } from "react";

import { Card, Button, Input } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import TxPopup from "../../../common/TxPopup";
import vaultContract from "../../../utils/vaultContract";
import { getUserAddress } from "../../../actions/web3Actions";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 10,
  },
  title: {
    fontWeight: 700,
    fontSize: 18,
    textAlign: "center",
    color: "#0000000",
  },
  value: {
    paddingTop: 5,
    fontWeight: 400,
    textAlign: "center",
    fontSize: 14,
    color: "#757575",
  },
  cover: {
    height: "200px",
    width: "100%",
    backgroundImage: `url('./../cover.png')`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));

const VaultContract = () => {
  const theme = useTheme();
  const classes = useStyles();

  const [input, setInput] = useState("");
  const [blockchainCase, setBlockchainCase] = useState(0);

  const updateVaultRewardFn = async () => {
    let userAddress = await getUserAddress();
    setBlockchainCase(1);

    let data = await vaultContract.methods.owner().call((err, res) => {
      console.log(err);
      return res;
    });
    console.log(data);

    const response = await vaultContract.methods
      .updateTotalRewards(10)
      .send(
        { from: userAddress, gasPrice: 10000000000 },
        function (error, transactionHash) {
          if (transactionHash) {
            console.log(error);
            console.log(transactionHash);
            setBlockchainCase(3);
          } else {
            setBlockchainCase(2);
          }
        }
      )
      .on("receipt", async function (receipt) {
        setBlockchainCase(5);
      })
      .on("error", async function (error) {
        setBlockchainCase(6);
      });
  };

  return (
    <div>
      <div>
        <h1 className="heading">Vault Contract</h1>
        <h6 className="paragraph">Features available for Vault Contract</h6>
      </div>
      <Card
        sx={{
          width: 300,
          height: 400,
          marginRight: "20px",
          marginTop: "30px",
          borderRadius: "10%",
          backgroundColor: "#212121",
          filter: "drop-shadow(0 0 0.5rem #e5e5e5)",
          [theme.breakpoints.down("sm")]: {
            paddingLeft: 0,
            paddingRight: 0,
            width: "100%",
            height: "100%",
          },
        }}
      >
        <div className={classes.cover} />
        <div className={classes.container}>
          <div className={classes.title}>Update Vault Reward</div>
          <div className={classes.value}>
            Updates the vault reward to that NFTs get updated into Farming
            Contract
          </div>
          {console.log(blockchainCase)}
          <div className="text-center mt-2">
            <Input
              type="number"
              placeholder="enter quantity"
              onChange={(e) => setInput(e.target.value)}
            />
          </div>
          <div className="text-center mt-2">
            <Button
              onClick={updateVaultRewardFn}
              variant="contained"
              sx={{
                background:
                  input == 0
                    ? "#e5e5e5"
                    : `linear-gradient(to right,#212121, #000000)`,
                color: "#f9f9f9",
                width: "fit-content",
                height: 40,
                textTransform: "none",
                borderRadius: "30px",
                fontSize: 15,
                padding: "5px 20px 5px 20px",
                "&:hover": {
                  background: "rgba(224, 7, 125, 0.7)",
                },
                cursor: "pointer",
                [theme.breakpoints.down("md")]: {
                  width: "fit-content",
                  fontSize: 12,
                },
              }}
              disabled={input == 0}
            >
              Update
            </Button>
          </div>
          {/* <TxPopup
            txCase={3}
            resetPopup={() => {
              console.log("hello");
            }}
            showPopup={true}
          /> */}
        </div>
      </Card>
    </div>
  );
};

export default VaultContract;

import { useState, useEffect, useRef } from "react";
import { Button, Input } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import SetAdmin from "./components/SetAdmin";
import { getTokenContractOwner } from "../../actions/smartActions";

const useStyles = makeStyles((theme) => ({
  tableRow: {
    marginTop: 10,
  },
}));

const Admins = () => {
  const theme = useTheme();
  const [token, setToken] = useState();
  const [staking, setStaking] = useState();
  const [launch, setLaunch] = useState();
  const classes = useStyles();

  const [tokenOwner, setTokenOwner] = useState(0);

  useEffect(() => {
    async function asyncFn() {
      let result = await getTokenContractOwner();
      setTokenOwner(result);
    }
    asyncFn();
  }, []);

  const onUpdate = (value) => {
    console.log(value);
  };

  return (
    <div>
      <div>
        <h1 className="heading">Administrators</h1>
        <h6 className="paragraph">Update admins of contracts</h6>
      </div>
      <div className="mt-5">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">No.</th>
              <th scope="col">Contract Name</th>
              <th scope="col">Current Admin</th>
              <th scope="col">New Admin</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr className={classes.tableRow}>
              <th scope="row">1</th>
              <td>$ORARE Contract</td>
              <td>{tokenOwner}</td>
              <td>
                <Input
                  value={token || ""}
                  onChange={(e) => setToken(e.target.value)}
                />
              </td>
              <td>
                <Button
                  sx={{
                    background: `linear-gradient(to right,#f9f9f9, #ffffff)`,
                    color: "#000000",
                    width: "fit-content",
                    height: 40,
                    textTransform: "none",
                    borderRadius: "30px",
                    fontSize: 15,
                    padding: "5px 20px 5px 20px",
                    "&:hover": {
                      background: "rgba(224, 7, 125, 0.7)",
                    },
                    cursor: "pointer",
                    [theme.breakpoints.down("md")]: {
                      width: "fit-content",
                      fontSize: 12,
                    },
                  }}
                  onClick={() => onUpdate(token)}
                >
                  Update
                </Button>
              </td>
            </tr>
            <tr className={classes.tableRow}>
              <th scope="row">2</th>
              <td>Staking Contract</td>
              <td>0x9D7117a07fca9F22911d371213fd5118A5F343</td>
              <td>
                <Input
                  value={staking || ""}
                  onChange={(e) => setStaking(e.target.value)}
                />
              </td>
              <td>
                <Button
                  sx={{
                    background: `linear-gradient(to right,#f9f9f9, #ffffff)`,
                    color: "#000000",
                    width: "fit-content",
                    height: 40,
                    textTransform: "none",
                    borderRadius: "30px",
                    fontSize: 15,
                    padding: "5px 20px 5px 20px",
                    "&:hover": {
                      background: "rgba(224, 7, 125, 0.7)",
                    },
                    cursor: "pointer",
                    [theme.breakpoints.down("md")]: {
                      width: "fit-content",
                      fontSize: 12,
                    },
                  }}
                  onClick={() => onUpdate(staking)}
                >
                  Update
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Admins;

import { Close } from "@mui/icons-material";
import { Box, Button, Modal, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useMetamask } from "../../../useMetamask";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getUserAddress } from "../../../actions/web3Actions";

const AddBrandPopup = ({
  openAddPopup,
  setOpenAddPopup,
  brandName,
  setBrandName,
  brandLogo,
  setBrandLogo,
  onUpdateBrandName,
  onUpdateBrandImage,
}) => {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const [minterAddress, setMinterAddress] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    (async () => {
      const address = await getUserAddress();
      setUser(address);
    })();
  }, []);

  const onAddBrand = async () => {
    const data = {
      address: minterAddress,
      fromAddress: "0xb2b0a3311742c977895a7f89f64a68c8b78c334a",
    };
    try {
      // const response = await axios.post(
      //   "https://superapp-backend.onerare.io/addMinter",
      //   data
      // );
      // console.log(`response `, response.data.result);
      await onUpdateBrandName(minterAddress);
      await onUpdateBrandImage(minterAddress);
      setOpenAddPopup(false);
    } catch (error) {
      console.log(error);
      setOpenAddPopup(false);
    }
  };

  return (
    <Modal
      open={openAddPopup}
      onClose={() => setOpenAddPopup(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <Box
          style={{
            background: "#212121",
            height: "max-content",
            width: "100%",
            maxWidth: 600,
            padding: "35px 25px",
            borderRadius: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: "25px",
            position: "relative",margin: "10px",
          }}
        >
          <Close
            style={{
              position: "absolute",
              right: 15,
              top: 15,
              fontSize:  md?28:36,
              cursor: "pointer",
            }}
            onClick={() => setOpenAddPopup(false)}
          />
          <Typography
            className="heading"
            style={{ fontSize:  md?28:36, marginBottom: 15 }}
          >
            Add a New Brand
          </Typography>
          <input
            type="file"
            onChange={(e) => setBrandLogo(e.target.files[0])}
            style={{
              maxWidth: "100%",
              margin: "0 auto",
            }}
          />
          <Box
            style={{
              width: "100%",
              display: "grid",
              gap: "5px",
              maxWidth: md?"100%":"75%",
              marginBottom: "25px",
            }}
          >
            <label htmlFor="name">Enter Brand Name</label>
            <input
              type="text"
              name="name"
              value={brandName || ""}
              onChange={(e) => setBrandName(e.target.value)}
              style={{
                background: "#434343",
                border: "none",
                height: "40px",
                borderRadius: "8px",
              }}
            />
            <br />
            <label htmlFor="minter">Enter Minter Address</label>
            <input
              type="text"
              name="minter"
              value={minterAddress || ""}
              onChange={(e) => setMinterAddress(e.target.value)}
              style={{
                background: "#434343",
                border: "none",
                height: "40px",
                borderRadius: "8px",
              }}
            />
          </Box>
          <Button
            sx={{
              background: `#D1FF1A`,
              color: "#000000",
              width: "fit-content",
              height: 46,
              textTransform: "none",
              borderRadius: 20,
              fontSize: md ? 14 : 18,
              padding: md ? "5px 10px" : "5px 20px",
              cursor: "pointer",
              width: md ? "100%" : 200,
              fontWeight: 600,
              "&:hover": {
                opacity: 0.7,
                background: `#D1FF1A`,
              },
            }}
            onClick={onAddBrand}
          >
            Add Brand
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddBrandPopup;

import { useState, useEffect } from "react";

import { Card, Button } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { getPartyEndTime } from "../../../actions/smartActions";
import CreateNft from "./CreateNft";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 10,
  },
  title: {
    fontWeight: 700,
    fontSize: 18,
    textAlign: "center",
    color: "#0000000",
  },
  value: {
    paddingTop: 5,
    fontWeight: 400,
    textAlign: "center",
    fontSize: 14,
    color: "#757575",
  },
  cover: {
    height: "200px",
    width: "100%",
    backgroundImage: `url('./../cover.png')`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));

const ERC1155Contract = () => {
  const theme = useTheme();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [endTime, setEndTime] = useState("");

  useEffect(() => {
    async function asyncFn() {
      let result = await getPartyEndTime();
      setEndTime(result);
    }
    asyncFn();
  }, []);
  const handleOpen = () => setOpen(true);

  return (
    <div>
      <div>
        <h1 className="heading">ERC1155 NFT Contract</h1>
        <h6 className="paragraph">Features available for ERC1155 Contract</h6>
      </div>
      <Card
        style={{
          width: 300,
          height: 350,
          marginRight: "20px",
          marginTop: "30px",
          borderRadius: "10%",
          backgroundColor: "#212121",
          filter: "drop-shadow(0 0 0.5rem #e5e5e5)",
          [theme.breakpoints.down("sm")]: {
            paddingLeft: 0,
            paddingRight: 0,
            width: "100%",
            height: "100%",
          },
        }}
      >
        <div className={classes.cover} />
        <div className={classes.container}>
          <div className={classes.title}>Create NFT</div>
          <div className={classes.value}>
            Click and declare the result of ERC1155 party only after{" "}
            <strong>10th Jan, 2022</strong>
          </div>
          <div className="text-center mt-2">
            <Button
              variant="contained"
              onClick={handleOpen}
              sx={{
                background: `linear-gradient(to right,#212121, #000000)`,
                color: "#f9f9f9",
                width: "fit-content",
                height: 40,
                textTransform: "none",
                borderRadius: "30px",
                fontSize: 15,
                padding: "5px 20px 5px 20px",
                "&:hover": {
                  background: "rgba(224, 7, 125, 0.7)",
                },
                cursor: "pointer",
                [theme.breakpoints.down("md")]: {
                  width: "fit-content",
                  fontSize: 12,
                },
              }}
            >
              Click to create
            </Button>
          </div>
        </div>
      </Card>
      <CreateNft open={open} setOpen={setOpen} handleOpen={handleOpen} />
    </div>
  );
};

export default ERC1155Contract;

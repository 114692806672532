import * as React from "react";

import { Card, Button } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import { Link } from "react-router-dom";
import { People, Person } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: 64,
    color: "#bdbdbd",
  },
  heading: {
    color: "#f9f9f9",

    fontSize: 18,
    fontWeight: 700,
    textAlign: "center",
  },
  type: {
    color: "#f9f9f9",
    fontSize: 12,
    fontWeight: 400,
    textAlign: "center",
  },
}));

const SetAdmin = () => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <div>
      <Card
        elevation={10}
        sx={{
          width: "100%",
          width: 240,
          height: 240,
          borderRadius: "30px",
          padding: "20px",
          background: "linear-gradient(to right,#4F5052,#000000)",
          filter: "drop-shadow(0 0 0.5rem #919191)",
          paddingBottom: "15px",
          [theme.breakpoints.down("sm")]: {
            paddingLeft: 0,
            paddingRight: 0,
            width: "100%",
            height: "100%",
          },
        }}
      >
        <div style={{ width: "100%" }}>
          <div className="text-center">
            <Person className={classes.icon} />
          </div>
          <div className={classes.heading}>Set New Admin</div>
          <div className="d-flex justify-content-center align-items-center ">
            <div
              style={{
                backgroundColor: "#C80C81",
                borderRadius: "50%",
                height: "5px",
                width: "5px",
                marginRight: 5,
              }}
            ></div>
            <div className={classes.type}>$ORARE TOKEN</div>
          </div>

          <div className="text-center mt-3">
            <Button
              variant="contained"
              sx={{
                background: `#E0247D`,
                color: "white",
                width: "fit-content",
                height: 40,
                textTransform: "none",
                borderRadius: "30px",
                fontSize: 15,
                marginRight: "5px",
                marginLeft: "5px",
                border: "1px solid rgba(224, 7, 125, 0.3)",
                padding: "5px 20px 5px 20px",
                "&:hover": {
                  background: "rgba(224, 7, 125, 0.7)",
                },
                [theme.breakpoints.down("sm")]: {
                  width: "fit-content",
                  fontSize: 13,
                },
              }}
            >
              Set Admin
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SetAdmin;
